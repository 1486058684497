import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import "./css/modal.css";


class MeetingModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.setShow = this.setShow.bind(this);
  }

  setShow(elem) {
    this.props.action(elem, 'meetingmodal');
  }


  makeChoice(choice){
    this.props.makechoice(choice);
    this.handleClose();
  }

  handleClose() {
    this.setShow(false);
  }

  handleShow() {
    this.setShow(true);
  }


  render() {
    const first = this.props.data.first;

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Meeting with <span style={{ fontWeight: 700 }}>{first}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div>Do you want to have an appointment with  {first} </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.makeChoice('nointerest')}>
            No
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.makeChoice('interest')}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MeetingModal;
