import React from "react";
import Slider from "react-rangeslider";

import './slider.css';

import Select from 'react-select';

class CustomSlider extends React.Component {


  handleChange(value){
    this.props.handleChange(this.props.user_part, value);
  };

  render() {

    return (
      <>
        <div
          style={{ borderBottom: "1px solid #dedede", paddingBottom: "10px", paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",

            }}
          >
            <div style={{ fontSize: ".8571em", color: "#9a9a9a" }}>{this.props.name}</div>
            <div
              style={{
                borderRadius: "4px",
                color: "#fff",
                backgroundColor: "#7b1fa2",
                padding: '5px 5px 5px 5px'
              }}
            >
              {this.props.viewvalue}
            </div>
          </div>

          <div style={{ marginTop: "13px" }}>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div>
                <img
                  src={this.props.Imgsrc}
                  style={{ width: "70px", borderRadius: "50%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '100%',

                }}
              >
                {" "}
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center' }}>
                  <div>{this.props.options.minValue}</div>
                  <div style={{ flex: 1, marginLeft: '5px', marginRight: '5px' }}>
                    <Slider
                      min={this.props.options.minValue}
                      max={this.props.options.maxValue}
                      value={this.props.value}
                      onChange={this.handleChange.bind(this)}
                      //onChangeComplete={this.handleChangeComplete}
                    />
                  </div>

                  <div>{this.props.options.maxValue}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class CustomSlect extends React.Component {

  state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };


  render() {
    const { selectedOption } = this.state;

    return (

      <>
        <div
          style={{ borderBottom: "1px solid #dedede", paddingBottom: "10px", paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",

            }}
          >
            <div style={{ fontSize: ".8571em", color: "#9a9a9a" }}>{this.props.user_part}</div>

          </div>

          <div style={{ marginTop: "13px" }}>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div>
                <img
                  src="https://seemodels.co/assets/imgs/height.jpg"
                  style={{ width: "70px", borderRadius: "50%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '100%',

                }}
              >
                {" "}
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center' }}>
                  <Select
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={this.props.options}
                    
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </>


    )
  }
}


class CustomMultiSlect extends React.Component {
  constructor() {
    super();
  this.state = {
    multiValue: [],
      filterOptions: [
        { value: "foo", label: "Foo" },
        { value: "bar", label: "Bar" },
        { value: "bat", label: "Bat" }
      ]
  };
  this.handleMultiChange = this.handleMultiChange.bind(this);
}

handleMultiChange(elem) {
  this.setState({
    multiValue : elem
  }, () => {
    console.log('state', this.state);
  })
 
}


  render() {
    const { selectedOption } = this.state;

    return (

      <>
        <div
          style={{ borderBottom: "1px solid #dedede", paddingBottom: "10px", paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",

            }}
          >
            <div style={{ fontSize: ".8571em", color: "#9a9a9a" }}>{this.props.user_part}</div>

          </div>

          <div style={{ marginTop: "13px" }}>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div>
                <img
                  src="https://seemodels.co/assets/imgs/height.jpg"
                  style={{ width: "70px", borderRadius: "50%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '100%',

                }}
              >
                {" "}
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center' }}>
                  <Select
                    value={this.state.multiValue}
                    options={this.state.filterOptions}
                    onChange={this.handleMultiChange}
                    isMulti = {this.props.isMulti}
                    
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </>


    )
  }
}



function CustomSingleSelect(props){

  function getValue(value){
    if(!props.isMulti){
      return props.options.filter(x => x.label === value);
    } else {
      var temp = [];

      for(var i = 0; i < value.length; i++){
       temp[i] = props.options.filter(x => x.label === value[i]);     
      }

      console.log('get value',temp);
      return temp;
    }
   
  }

  function change(value, part){
    console.log('change',value);
    props.change(value.label, part);
  }


  let Imgsrc = '';
  switch (props.user_part) {
    case 'Hair': Imgsrc = "https://seemodels.co/assets/imgs/haircolor.jpg";
      break;
    case 'Eyes': Imgsrc = "https://seemodels.co/assets/imgs/eyescolor.jpg";
      break;
  }
  
  return (
    

      <>
        <div
          style={{ borderBottom: "1px solid #dedede", paddingBottom: "10px", paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",

            }}
          >
            <div style={{ fontSize: ".8571em", color: "#9a9a9a" }}>{props.user_part}</div>

          </div>

          <div style={{ marginTop: "13px" }}>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div>
                <img
                  src =  { Imgsrc }
                  style={{ width: "70px", borderRadius: "50%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '100%',

                }}
              >
                {" "}
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center' }}>
                  <Select
                    value={props.value}
                    options={props.options}
                    isMulti = {props.isMulti}    
                    value={ getValue(props.value) }
                    onChange={ (e) => change(e, props.part) }
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </>


    )
  
}



export { CustomSlect, CustomSlider, CustomMultiSlect, CustomSingleSelect };
