import React from "react";

import "../../../css/contractadmin.css";
import './customInput.css'

import {  CustomMultiSlect } from '../../common/slider';


class SpecProfile extends React.Component {
  render() {


    return (
      <div className="d">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "15px"
          }}
        >
          <div className="card2">
            <div className="cardHeader2 purple">
              <div className="cardNoteHeader">Edit Specificty</div>
            </div>
            <div className="cardBody2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <div>
                <CustomMultiSlect user_part="Tatoos" isMulti={true}/>
                <CustomMultiSlect user_part="Piercing" isMulti={true} />
                </div>

              </div>
            </div>

            <div className="card-footer">
              <div
              style = {{ display: 'flex', flexDirection : 'row' }}
             className="mobicenter"
              >
                <div className="card-btn rectangle">Update Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SpecProfile;
