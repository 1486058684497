import React from "react";
import AdminNav from "./components/adminnav";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

import "../../css/contractadmin.css";

class Contract extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showcalendar : true
    };    
    this.calendarRef = React.createRef();
  }



  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
   
  }

  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
      var rect = document.getElementById('cardbody').getBoundingClientRect();
      var windowHeight = window.innerHeight;
      var height = windowHeight - rect.top - 60;
      console.log('position from the top', rect.top);

      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.setOption("height", height);
  }



  render() {
    return (
      <React.Fragment>
                       <div className="dashboardCtn">
   <AdminNav title = { 'Agenda' }  icon="ok" />

   <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "10px"
          }}
        >
          <div className="card2">
            <div className="cardHeader2">
              <div className="cardNoteHeader">Agenda</div>
            </div>
            <div className="cardBody2" id="cardbody"   style = {{ marginTop: '10px', padding: '15px' }}>
              {
                this.state.showcalendar && (
                  <FullCalendar 
                  id="fullcalendar"
                 ref={this.calendarRef}
                 selectable={true}
                 firstDay={1}
                 eventClick={this.showEventDetails}
                 eventResize={this.resizeEvent}
                 eventDrop={this.resizeEvent}
                 select={this.handleDateClick}
                // events={this.state.calendarEvents}
                 minTime={"09:00:00"}
                 maxTime={"22:00:00"}
                 defaultView="timeGridWeek"
                 plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              /> 
                )
              }

            </div>
          </div>
        </div>


   </div>


      </React.Fragment>
    );
  }
}

export default Contract;
