import React from "react";
import "../css/modelsummary.css";


class Mensublock extends React.Component {


  render() {
    return (
 
      <div className="mensuBlock">

        {
          this.props.first && (
            <div style= {{ fontSize: '24px', fontWeight : '700', letterSpacing: '1px' }}>
          {this.props.first}
          </div>
          )
        }
  
  {
    this.props.mensurations && (
      <div style = {{ display: 'flex', flexDirection: 'row', justifyContent : 'space-evenly', flexWrap: 'wrap', paddingRight: '10px', paddingLeft: '10px' }}>
        {
          this.props.mensurations.map(x => {
            return(
              <div style={{ marginRight: '5px', marginLeft: '5px' }} key={x.name}>
              {x.name} : {x.valeur}
                </div>
            )
          })
        }
      </div>
    )
  }

   
      </div>
    );
  }
}

export default Mensublock;
