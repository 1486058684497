import React from "react";
import  Modelsumary  from "../components/modelsumary";

import { Redirect } from "react-router-dom";
import httpService from '../http';

import logo from "../assets/logoo3.png";


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, flexCtn: 0, modelsdata: [] };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount() {
    let postData = {"key" : httpService.getLocalStorage('key') };
    let url = 'getModels.php';
    console.log('postData',postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log('res',res);
    this.setState({
      modelsdata : res.models,
      admin : res.admin,
      isagency : res.isAgency
    })
    console.log('res',res);
    
    if(res.models){
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

  }


  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    const height =  document.getElementById('flexCenter').clientHeight;

    this.setState({ width: window.innerWidth, height: window.innerHeight,  flexCtn: height });
  }


  render() {
    let margintop;
    if(this.state.width < 640){
      margintop = 50;
    } else {
      margintop = Math.max( 150, this.state.height / 2 - this.state.flexCtn/2);
    }
     



    if(this.state.modelsdata){
      return (
        <React.Fragment>
        <div className = "logo" >
   
   <img
   src={logo}
   alt="Logo"
   style={{ height: "56px", marginTop: "15Px", zIndex: "3000" }}
 />
   </div>
   <div style={{ marginTop: margintop, justifyContent : 'center', display: 'flex', width: '100%', height: '100%' }}>
   <div id="flexCenter" style = {{ justifyContent : 'center', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} >
     {
       this.state.modelsdata && this.state.modelsdata.map(x => {
         return <Modelsumary 
         key={x.identifiant} 
         first={x.first} 
         last={x.last} 
         portrait={x.portrait} 
         pictures={x.pictures} 
         identifiant={x.identifiant} 
         admin={ this.state.admin }
         isagency = { this.state.isagency }
         />
       })
     }
 
 </div>
   </div>
        </React.Fragment>


    );
    } else {
      return(
      <Redirect to={"/login/"} />
      )
    }

  }
}

export default Home;
