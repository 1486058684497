import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import './lostpwd.css';

class LostPwdModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading : false
    }
    this.setShow = this.setShow.bind(this);
  }

  setShow(elem) {
    this.props.action('pwdmodal', elem);
  }

  handleClose() {
    this.setShow('close');
  }

  render() {
    
    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered  dialogClassName="my-modal" >
        <Modal.Header closeButton >
          <Modal.Title>
          Retreive Your Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <iframe src="https://seemodels.co/pages/pwd" height="500" className="my-modal-inner" />     

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={ () => this.handleClose() }>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}


export default LostPwdModal;
