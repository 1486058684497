import React from "react";

import "../../../css/contractadmin.css";
import './customInput.css'

import { CustomSlider, CustomSlect } from '../../common/slider';
import httpService from "../../../http";

class MensuProfile extends React.Component {

  constructor() {
    super();
  this.state = {
    data : [

    ]
  };
}
  
async componentDidMount(){
  let res = await this.getData();
  this.setData(res);
}

setData(elem){

this.setState({
  data : elem.values
}, () => {
  console.log('this state', this.state.data);
})
}

  async getData(){
 
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: 'getmodelmensu',
    };
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    console.log( 'res', res);
    return res;
  }


  renderValue(value, part, unity = 'cm'){
   
    var extension;

    if (part !== 'user_shoe'){
        if (unity == 'cm'){
          extension = ' cm';
          return value + extension;
        } else {
         extension = '';
         var realFeet =  value / 30.48;
          if (part == 'user_height'){
           var feet = Math.floor(realFeet);
           var inches = Math.round((realFeet - feet) * 12);
           return feet + " ' " + inches + ' " ';
          } else {
            var inches = Math.round(realFeet * 12)
            return inches + ' " ';
          }
 
        }
       } else {
         // Si Shoes
return value;
       }
}

handleChange(part, valueD){
 
  const temp = [...this.state.data];

  const index = this.findIndex(temp, part);
  console.log('handle change', index);
  temp[index].value = valueD;

  this.setState({
    data : temp
  })

}

findIndex(array, value){
  var i = 0;
  var index = -1;

  for(i; i < array.length; i++){
    if(array[i].part == value){
     index = i;
    }
  }
  return index;
}


async goUpdate(){

  var DATA = [];
  for(var i = 0; i < this.state.data.length; i++){
    DATA[i] = this.state.data[i].value;
  }

  var LABELS = [];
  for(var j = 0; j < this.state.data.length; j++){
    LABELS[j] = this.state.data[j].part;
  }

  let postData = {
    key: httpService.getLocalStorage("key"),
    action: 'updatemodalinfos',
    data : {'data' : DATA, 'labels' : LABELS}
  };

  console.log('postdata', postData);

  let url = "mgmt_dashboard.php";
  let res = await httpService.goBackOffice(url, postData);
  console.log( 'res', res);

  }



  render() {


    return (
      <div className="b">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "15px"
          }}
        >
          <div className="card2">
            <div className="cardHeader2 purple">
              <div className="cardNoteHeader">Edit Mensurations</div>
            </div>
            <div className="cardBody2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <div>
                  {
                    this.state.data && this.state.data.map( (x,index) => {

                      let Imgsrc = '';
                      switch (x.name) {
                        case 'Height': Imgsrc = "https://seemodels.co/assets/imgs/height.jpg";
                          break;
                        case 'Waist': Imgsrc = "https://seemodels.co/assets/imgs/waist.jpg";
                          break;
                  
                        case 'Hips': Imgsrc = "https://seemodels.co/assets/imgs/hips.jpg";
                          break;
                  
                        case 'Chest': Imgsrc = "https://seemodels.co/assets/imgs/chest.jpg";
                          break;
                  
                        case 'Shoes': Imgsrc = "https://seemodels.co/assets/imgs/shoesize.jpg";
                          break;
                      }


                      return(
                        <CustomSlider 
                       
                        key={ x.part } 
                        user_part={ x.part } 
                        viewvalue = { this.renderValue(x.value, x.part) } 
                        name = { x.name }
                        url = { x.url }
                        options = { x.options }
                        Imgsrc = { Imgsrc }
                        value = { x.value }
                        handleChange = { this.handleChange.bind(this) }
                        />
                      )
                    })
                  }
              
                </div>

              </div>
            </div>

            <div className="card-footer">
              <div
              style = {{ display: 'flex', flexDirection : 'row' }}
             className="mobicenter"
              >
                <div className="card-btn rectangle" onClick = { () => this.goUpdate() }>Update Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MensuProfile;
