import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import './adminnav.css';

class AdminNav extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: '', height: ''
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {

    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }


  render() {

    return (
      <React.Fragment>

        <div class="topbarCtn">
          <div style={{
            color: '#a9afbbd1',
            height: '50px',
            fontSize: '1.125rem',
            lineHeight: '30px',
            padding: '.625rem 0',
            fontWeight: '300',
            marginLeft: '1rem'
          }}>
            {this.state.width < 992 && (
              <>
                <Link to="/home/" style={{ textDecoration: 'none', color: 'white' }}>
                  <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '20px', fontSize: "18px" }} />
       Back
       </Link>
              </>
            )

            }

            {this.state.width > 992 && (
              <>
                {this.props.title}
              </>
            )

            }

          </div>
          { 
            this.props.icon && this.props.icon == 'ok' && (
              <div style={{ justifyContent: 'flex-end !important', display: 'flex', flexDirection: 'row' }}>
              <div>
                <FontAwesomeIcon icon={faBell} style={{ marginLeft: '15px', marginRight: '20px', fontSize: "18px" }} />
              </div>
              <div>
                <FontAwesomeIcon icon={faUser} style={{ marginLeft: '15px', marginRight: '20px', fontSize: "18px" }} />
              </div>
  
            </div>
            )
          }

        </div>

      </React.Fragment>
    );
  }
}


export default AdminNav;