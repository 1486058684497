import React from "react";
import "../css/picturesblock.css";

import { ActionsDetails } from "./actions";

import { Player } from "video-react";

import Picto from "./static/picto";
import httpService from "../http";
import JpegModal from "../components/modal/jpegmodal";
import UploadModal from "../components/modal/uploadmodal";

import UploadMovieModal from "../components/modal/uploadmoviemodal";


class Picturesblock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: this.props.first,
      last: this.props.last,
      width: 0,
      height: 0,
      picturesBlockWidth: 0,
      imgDivider: 3,
      jpegmodal: false,
      jpegdata: "",
      selectedFile: [],
      uploadmodal: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.actions = this.actions.bind(this);
    this.uploadmodal = React.createRef();
    this.uploadmoviemodal = React.createRef();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    console.log("props", this.props);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const picturesBlockWidth = document.getElementById("picturesBlock")
      .clientWidth;
    if (window.innerWidth < 640) {
      this.changeDisplay(1);
    }
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      picturesBlockWidth: picturesBlockWidth,
      blankBlockWidth: this.state.picturesBlockWidth / 3 - 10
    });
  }

  changeDisplay(size) {
    this.setState({ imgDivider: size });
  }

  actions(urlmid, urlhigh, index, type) {
    console.log("actions", index, type, urlmid, urlhigh);
    if (type == "jpeg") {
      this.jpegDownload(urlmid, urlhigh);
    } else {
      this.props.action(type, index);
    }
  }

  switchType(type) {
    this.props.action("switchtype", type);
    if (type == "polaroids") {
      this.saveChoice("gopola");
    }

    if (type == "videos") {
      this.saveChoice("govideo");
    }
  }

  async saveChoice(elem) {
    let data = {
      action: "savelog",
      model: this.props.identifiant,
      choice: elem
    };

    let postData = {
      key: httpService.getLocalStorage("key"),
      data: data
    };

    let url = "saveLog.php";

    console.log("postData", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("res models", res);
    //this.setData(res);
  }

  mgmtModal(elem, modaltype) {
    console.log("mgmt modal", elem, modaltype);
    switch (modaltype) {
      case "jpegmodal":
        this.setState({
          jpegmodal: false
        });
        break;

        case "uploadmoviemodal":
          this.setState({
            uploadmoviemodal: false
          }, () => {
            this.forceRefresh();
          });
          break;

      case "uploadmodal":
        this.setState(
          {
            uploadmodal: false
          },
          () => {
            this.forceRefresh();
          }
        );
        break;
    }
  }

  forceRefresh() {
    this.props.action("refresh", this.state.albumid);
  }

  jpegDownload(urlmid, urlhigh) {
    console.log("jpeg download", urlmid, urlhigh);

    let url = [];
    url.resize = urlmid;
    url.fullurl = urlhigh;

    this.setState({
      jpegdata: url,
      jpegmodal: true
    });
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  upload(albumidE, modelidentifiant) {
    console.log("test commu");
    this.setState({
      albumid: albumidE,
      identifiant: modelidentifiant
    });
    if(albumidE !== 'videos'){
      document.getElementById("fileinput").click();
    } else {
      document.getElementById("movieinput").click();
      
    }
 
  }

  onChangeHandler = event => {
    this.setState(
      {
        selectedFile: event.target.files
      },
      () => {
        console.log("selected file", this.state.selectedFile);
        this.openUploadModal();
        this.uploadmodal.current.uploadPics();
      }
    );
  };

  onChangeHandler2 = event => {
    this.setState(
      {
        selectedFile: event.target.files
      },
      () => {
        console.log("selected file", this.state.selectedFile);
        this.openUploadModal();
        this.uploadmoviemodal.current.uploadPics();
      }
    );
  };

  openUploadModal() {
    this.setState({
      uploadmodal: true
    });
  }

  openUploadModal() {
    this.setState({
      uploadmoviemodal: true
    });
  }

  render() {
    let blankBlockWidth;
    let maxWidthCalculate = "1225px";
    let imgwidth;
    let imgheight;

    if (this.state.imgDivider === 1) {
      if (this.state.width < 640) {
        console.log(" state width", this.state.width);
        maxWidthCalculate = 0.8 * this.state.width;
        imgwidth = maxWidthCalculate - 5;
        imgheight = imgwidth * 1.4;
      } else {
        imgwidth = 1190 / 2 - 15;
        imgheight = imgwidth * 1.4;
      }
    } else {
      blankBlockWidth =
        this.state.picturesBlockWidth / this.state.imgDivider - 10;
      imgwidth = blankBlockWidth / 2 - 15;
      imgheight = imgwidth * 1.4;
    }

    const gallery = this.props.gallery;
    const polaroids = this.props.polaroids;
    const videos = this.props.videos;
    const videoWidth = 0.95 * this.state.width;

    //console.log('block width', blankBlockWidth);
    return (
      <React.Fragment>
        <JpegModal
          show={this.state.jpegmodal}
          data={this.state.jpegdata}
          action={this.mgmtModal.bind(this)}
          identifiant={this.state.first}
        />
        <UploadModal
          ref={this.uploadmodal}
          show={this.state.uploadmodal}
          files={this.state.selectedFile}
          action={this.mgmtModal.bind(this)}
          albumid={this.state.albumid}
          identifiant={this.state.identifiant}
        />

        <UploadMovieModal
          ref={this.uploadmoviemodal}
          show={this.state.uploadmoviemodal}
          files={this.state.selectedFile}
          action={this.mgmtModal.bind(this)}
          albumid={this.state.albumid}
          identifiant={this.state.identifiant}
        />

        <input
          type="file"
          name="file"
          multiple
          onChange={this.onChangeHandler}
          id="fileinput"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
        />


        <input
          type="file"
          name="file"
          onChange={this.onChangeHandler2}
          id="movieinput"
          accept="video/*"
          style={{ display: "none" }}
        />

        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {gallery && (
              <div
                onClick={() => this.switchType("gallery")}
                className={
                  "picto " +
                  (this.props.showtype == "gallery" ? "selected" : "")
                }
              >
                <div>Gallery</div>
              </div>
            )}
            {polaroids && (
              <div
                onClick={() => this.switchType("polaroids")}
                className={
                  "picto " +
                  (this.props.showtype == "polaroids" ? "selected" : "")
                }
              >
                Polaroids
              </div>
            )}
            {videos && (
              <div
                onClick={() => this.switchType("videos")}
                className={
                  "picto " + (this.props.showtype == "videos" ? "selected" : "")
                }
              >
                Videos
              </div>
            )}
          </div>

          {
            this.props.showtype !== 'videos' && (
              <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              {this.state.width > 640 && (
                <React.Fragment>
                  <div className="picto" onClick={() => this.changeDisplay(3)}>
                    <Picto type={"small"} status={this.state.imgDivider} />
                  </div>
  
                  <div className="picto" onClick={() => this.changeDisplay(2)}>
                    <Picto type={"medium"} status={this.state.imgDivider} />
                  </div>
                  <div className="picto" onClick={() => this.changeDisplay(1)}>
                    <Picto type={"large"} status={this.state.imgDivider} />
                  </div>
                </React.Fragment>
              )}
            </div>
            )
          }


        </div>

        <div
          id="picturesBlock"
          className="picturesBlock"
          style={{ maxWidth: maxWidthCalculate }}
        >
          {(this.props.showtype === "gallery" ||
            this.props.showtype === "album") &&
            this.props.gallery &&
            this.props.gallery.length > 0 &&
            this.props.gallery.map((x, index) => {
              const backImg = "url(" + x.resize + ")";

              return (
                <React.Fragment key={index}>
                  <div
                    className={
                      "blankBLock " +
                      ((x.status == "1" && (this.props.rights.isAdmin)) ? "picsSelected" : "picsUnselected")
                    }
                    id={index}
                  >
                    <div className="blanckItem">
                      <div
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          backgroundImage: backImg,
                          width: imgwidth,
                          height: imgheight,
                          position: "relative",
                          overflow: "hidden"
                        }}
                      >
                        <ActionsDetails
                          identifiant={index}
                          onclick={this.actions.bind(this, x.resize, x.fullurl)}
                          showtype={this.props.showtype}
                          imgid={x.id}
                          status={x.status}
                          admin={this.props.admin}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

          {(this.props.showtype == "gallery" ||
            this.props.showtype == "polaroids") &&
            ((this.props.polaroids && this.props.polaroids.length == 0) ||
              (this.props.gallery && this.props.gallery.length == 0)) && (
              <div>
                There is no public pictures selected, you have to select some
                pictures in the album part.
              </div>
            )}

          {this.props.showtype === "polaroids" &&
            this.props.polaroids &&
            this.props.polaroids.map((x, index) => {
              const backImg = "url(" + x.resize + ")";

              return (
                <React.Fragment>
                  <div className="blankBLock" id={index}>
                    <div className="blanckItem">
                      <div
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          backgroundImage: backImg,
                          width: imgwidth,
                          height: imgheight,
                          position: "relative",
                          overflow: "hidden"
                        }}
                      >
                        <ActionsDetails
                          identifiant={index}
                          onclick={this.actions.bind(this, x.resize, x.fullurl)}
                          showtype={this.props.showtype}
                          imgid={x.id}
                          status={x.status}
                          admin={this.props.admin}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

          {this.props.showtype === "videos" &&
            this.props.videos &&
            this.props.videos.map((x, index) => {
              const videosrc = x.src;

              return (
                <React.Fragment>
                  <div style={{ width: videoWidth, marginTop: "20px" }}>
                    <Player key={x.src} style={{ maxHeight: "400px" }}>
                      <source src={videosrc} />
                    </Player>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

export default Picturesblock;
