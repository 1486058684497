import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import "./css/modal.css";

import CustomFunc from '../../customfunc';
import ProgressBar from 'react-bootstrap/ProgressBar'

import JsZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

class ZipAlbumModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quality: "Medium",
      isLoadingZip: false,
      indicateur: 0,
    };
    this.change = this.change.bind(this);
    this.downloadZip = this.downloadZip.bind(this);
    this.goZip = this.goZip.bind(this);
  }

  change(vartochange, value) {
    console.log("change", vartochange, value);
    this.setState(
      {
        [vartochange]: value
      },
      () => {
        console.log("state", this.state);
      }
    );
  }

  setShow(elem) {
    this.props.action("openzipmodal", elem);
  }

  handleClose() {
   // this.props.goback('zip');
    this.setShow(false);
  }

  handleShow() {
    this.setShow(true);
  }

  downloadZip() {
  
    this.setState({
      isLoadingZip : true
    }, () => {
      console.log('before switch', this.state); 
      this._goZip();
   })

      }

      _goZip(){
        let content = this.props.content;
        let zipname = this.props.identifiant + "_" + "_pics.zip";

        const temp = [];
        for(var i = 0; i < content.length; i++){
       
         
          if (this.state.quality == "Medium") {
            temp[i] = content[i].resize;
          }
          if (this.state.quality == "High") {
            temp[i] = content[i].fullurl;
          }
       
        }
        this.goZip(temp, zipname, this.props.identifiant);
      }

     async goZip(content, zipname, first){
      let res =   await this.goZip(content, zipname, first);
      console.log('zip',res);
        this.setState({
          isLoadingZip : false
        })
      }
    

      async goZip(data, zipname, picsname){
        var zip = new JsZip();
       
        for (var i = 0; i < data.length; i++) {
          var filename = "pics_" + picsname + "_" + i + ".jpg";
          const indicateurE = (i / data.length * 100).toFixed(0);
          this.setState({
            indicateur: indicateurE,
          });

          zip.file(filename, this.urlToPromise(data[i], indicateurE), { binary: true });
        }
    
            await zip.generateAsync({ type: "blob" }).then(function(content) {
                saveAs(content, zipname); 
            });
        
      }

      customSaveAs(content, zipname){
        saveAs(content, zipname);
      }

      urlToPromise(url, indicateurE) {
        return new Promise(function(resolve, reject) {
          JSZipUtils.getBinaryContent(url, function(err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        });
      }

  
  render() {
 
    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Download this Album
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
             this.state.isLoadingZip == true && (
              <div style = {{ width: '100%', marginTop: '10px' }}>
              <ProgressBar animated  now={this.state.indicateur} label={`${this.state.indicateur}%`} />
              </div>
             )
           }

{
             this.state.isLoadingZip == false && (
             <>
              <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
            </div>
  
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px"
              }}
            >
              <div>
                Choose quality :{" "}
                <span style={{ fontWeight: 700 }}>{this.state.quality}</span>
              </div>
  
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Change
                </button>
  
                <div className="dropdown-menu">
                  <a
                    className="dropdown-item"
                    onClick={() => this.change("quality", "Medium")}
                  >
                    Medium
                  </a>
  
                  <a
                    className="dropdown-item"
                    onClick={() => this.change("quality", "High")}
                  >
                    High
                  </a>
                </div>
              </div>
            </div>
</>
             )
           }
           
          
    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleClose()}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={!this.state.isLoadingZip ? () => this.downloadZip() : null}
            disabled={ this.state.isLoadingZip }
          >
            {this.state.isLoadingZip ? "Loading…" : "Download"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ZipAlbumModal;
