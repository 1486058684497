
import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";

import httpService from "../../http";
import ProgressBar from 'react-bootstrap/ProgressBar'

class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indicateur : 0
    }
  }

  handleClose(type = 'none'){
      this.props.action('','uploadmodal', type);
  }

  async fileUpload(file){   
    
    const formData = new FormData()
    formData.append('file', file);
    formData.append('action', 'savepics');
    formData.append('albumid', this.props.albumid);
    formData.append('identifiant', this.props.identifiant);
    formData.append('key', httpService.getLocalStorage('key'));

    let url = 'mgmt_album.php';

    let res = await httpService.goBackOffice(url, formData);
    console.log('res');
return res;

  }

  async uploadPics(){
      console.log('upload pics', this.props.files);
      var indicateurE;

      for(var i = 0; i < this.props.files.length ; i++){
        indicateurE = (i /  this.props.files.length * 100).toFixed(0);
        console.log('indicateur', indicateurE);
        this.setState({
          indicateur: indicateurE,
         // indicateurBool : true
        });

        let res = await this.fileUpload(this.props.files[i]);
        console.log('file', res);
        if(res !== 'ok'){
          // Create Error Allert
          break;
        }
      }
      this.handleClose('success');
  }


  render() {

    let files = this.props.files;

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Pictures</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <ProgressBar animated  now={this.state.indicateur} label={`${this.state.indicateur}%`} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleClose()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => this.uploadPics()}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UploadModal;
