import React, { useLayoutEffect, useState } from "react";

import "../css/admin.css";

import logo from "../assets/logoo3.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";




import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link
} from "react-router-dom";

import Dashboard from "../components/admin/dashboard";
import Contract from "../components/admin/contract";
import AgendaComp from "../components/admin/agendacomp";
import UserProfile from "../components/admin/userprofile";

import httpService from "../http";
import axios from "axios"

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function useHttpListCall() {
  const [background, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  let postData = {
    key: httpService.getLocalStorage("key"),
    action: "getbackground",
  }

  React.useEffect(() => {
    axios
      .post('https://bmyscouter.co/db/fullpackage/mgmt_dashboard.php', postData)
      .then(function(response) {
        console.log('get background', response.data);
        setData(response.data);
      })
      .catch(function(error) {
        setError(error);
      });
  }, ['https://bmyscouter.co/db/fullpackage/mgmt_dashboard.php']);
  
  return { background, error };
}

function ShowWindowDimensions(props) {
  const [width, height] = useWindowSize();
  return { width };
}

function ModelAdmin() {
  let { path, url } = useRouteMatch();
  var pathname = window.location.pathname;

  console.log("path", pathname);
  let windowwidth = ShowWindowDimensions().width;

  let menuWidth = '260px';
  let displayD = 'block';
  console.log("window width", windowwidth);
  if (windowwidth < 640) {
    console.log("mobile");
    menuWidth = 0;
  } else {
    console.log("not mobile");
    menuWidth = "260px";
  }

  let { Background } = useHttpListCall();
  console.log("Background width", Background);
  let backgroundImg = 'url(' + Background + ')';

  return (
    <React.Fragment>
      <React.Fragment>


        <div >

          <div
            className="adminMenuPanel removeOnMobile"
            style={{ minWidth: menuWidth, maxWidth: menuWidth, display: "block" }}
          >
            <div
              className="removeOnMobile"
              style={{
                position: "absolute",
                zIndex: 10,
                height: "100%",
                width: "100%",
                top: "0",
                left: "0",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundImage: backgroundImg
              }}
            ></div>
          </div>

          <div

            style={{
              backgroundColor: "#1f283e",
              opacity: 0.94,
              minWidth: menuWidth,
              maxWidth: menuWidth,
              height: "100vh",
              position: "absolute",
              top: 0,
              left: 0,
              color: "white",
              zIndex: 100,
              display: "block"
            }}
          >
            <div style={{ color: "white", zIndex: "20", display: displayD }}>
              <div className="logoAdmin removeOnMobile">
                <img src={logo} style={{ width: "200px" }} />
              </div>
              <div id="navMobile">
                <ul className="nav" >


                <li className="li removeOnMobile">
                    <Link to="/home/" className="adminLink">
                      {" "}
                      <div
                        className={
                          "base " }
                      >
                        <div className="btnFlex">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              icon={faChevronLeft}
                              style={{
                                marginLeft: "15px",
                                marginRight: "20px",
                                fontSize: "14px"
                              }}
                            />
                          </div>
                          <div className="btnTitle">Get Back</div>
                        </div>
                      </div>
                    </Link>
                  </li>


                  <li className="li ">
                    <Link to={`${url}/dashboard`} className="adminLink">
                      {" "}
                      <div
                        className={
                          "base " +
                          (pathname == "/modeladmin/dashboard" ? "active" : null)
                        }
                      >
                        <div className="btnFlex">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              icon={faSlidersH}
                              style={{
                                marginLeft: "15px",
                                marginRight: "20px",
                                fontSize: "14px"
                              }}
                            />
                          </div>
                          <div className="btnTitle">Dashboard</div>
                        </div>
                      </div>
                    </Link>
                  </li>

                  <li className="li">
                    <Link to={`${url}/userprofile`} className="adminLink">
                      {" "}
                      <div
                        className={
                          "base " +
                          (pathname == "/modeladmin/userprofile" ? "active" : null)
                        }
                      >
                        <div className="btnFlex">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{
                                marginLeft: "15px",
                                marginRight: "20px",
                                fontSize: "14px"
                              }}
                            />
                          </div>
                          <div className="btnTitle">User Profile</div>
                        </div>
                      </div>
                    </Link>
                  </li>

                  <li className="li">
                    <Link to={`${url}/contract`} className="adminLink">
                      {" "}
                      <div
                        className={
                          "base " +
                          (pathname == "/modeladmin/contract" ? "active" : null)
                        }
                      >
                        <div className="btnFlex">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              icon={faFileContract}
                              style={{
                                marginLeft: "15px",
                                marginRight: "20px",
                                fontSize: "14px"
                              }}
                            />
                          </div>
                          <div className="btnTitle">Contract</div>
                        </div>
                      </div>
                    </Link>
                  </li>

                  <li className="li">
                    <Link to={`${url}/agenda`} className="adminLink">
                      {" "}
                      <div
                        className={
                          "base " +
                          (pathname == "/modeladmin/agenda" ? "active" : null)
                        }
                      >
                        <div className="btnFlex">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              icon={faCalendarCheck}
                              style={{
                                marginLeft: "15px",
                                marginRight: "20px",
                                fontSize: "14px"
                              }}
                            />
                          </div>
                          <div className="btnTitle">Agenda</div>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>


      </React.Fragment>

      <Switch>
        <Route  path={`${path}/dashboard`}>
          <Dashboard />
        </Route>

        <Route path={`${path}/userprofile`}>
          <UserProfile />
        </Route>

        <Route path={`${path}/contract`}>
          <Contract />
        </Route>

        <Route path={`${path}/agenda`}>
          <AgendaComp />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default ModelAdmin;
