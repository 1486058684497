import React from "react";

import GraphComp from "./components/graph";
import AllCardComp from "./components/card";
import AllNoteComp from "./components/notecomp";
import AdminNav from "./components/adminnav";

import ProfileSummary from "./components/profilsummary"

class Dashboard extends React.Component {
  render() {

    return (
      <React.Fragment>
        <div className="dashboardCtn">
          <AdminNav title={'Dashboard'} icon="ok" />
          <div
            className="flexRow reverseOnMobile padding"
            style={{
              marginTop: "50px"
            }}
          >
            <GraphComp
              title={"Daily Visit"}
              subtitle={"On 4 last week"}
              footer={"Updated on 22/03/2020 at 11h00"}
              type={'Line'}
              color={"green"}
            />
            <GraphComp
              title={"Location VIsit"}
              subtitle={"On 4 last week"}
              footer={"Updated on 22/03/2020 at 11h00"}
              type={'Bar'}
              color={"red"}
            />
            <ProfileSummary />


          </div>

          <div
            className="flexRow padding"
          >
            <AllCardComp />

          </div>

          <div

            className="flexRow padding"

          >
            <AllNoteComp />


          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
