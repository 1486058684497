import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import "./css/modal.css";
import Toggle from "react-bootstrap-toggle";

import httpService from "../../http";

class ShareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedpackage: "Portfolio",
      isLoading: false,
      toggleActiveShare: false,
      toggleActiveCompcard: false,
      toggleActiveDownload: false
    };
    this.changePackage = this.changePackage.bind(this);
    this.sharePackage = this.sharePackage.bind(this);
    this.goSharePackage = this.goSharePackage.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  setShow(elem) {
    this.props.action(elem, "sharemodal");
  }

  handleClose() {
    this.setShow(false);
    //
  }

  handleShow() {
    this.setShow(true);
  }

  changePackage(elem) {
    this.setState({
      selectedpackage: elem
    });
  }

  checkEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async sharePackage() {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.goSharePackage();
      }
    );
  }

  async goSharePackage() {
    var sendto = document.getElementById("sendto").value;
    var object = document.getElementById("object").value;
    var message = document.getElementById("message").value;

    var share = this.state.toggleActiveShare;
    var compcard = this.state.toggleActiveCompcard;
    var download = this.state.toggleActiveDownload;
 

    if (this.checkEmail(sendto) && object.length > 2) {
      let data = {
        sendto: sendto,
        object: object,
        message: message,
        right: this.state.selectedpackage,
        modelidentifiant: this.props.data.identifiant,
        share : share,
        compcard : compcard,
        download : download
      };
      var postData = { action: "sharepackage", data: data };
      console.log("postdata", postData);

      let url = "sharePackage.php";

      let res = await httpService.goBackOffice(url, postData);
      console.log("share package", res);
      if (res.status == "ok") {
        this.setState(
          {
            isLoading: false
          },
          () => {
            this.props.goback("share");
            this.handleClose();
          }
        );
      }
    }
  }

  onToggle(elem) {
    switch (elem) {
      case "share":
        this.setState({ toggleActiveShare: !this.state.toggleActiveShare });
        break;

      case "compcard":
        this.setState({
          toggleActiveCompcard: !this.state.toggleActiveCompcard
        });
        break;

      case "download":
        this.setState({
          toggleActiveDownload: !this.state.toggleActiveDownload
        });
        break;
    }
  }

  render() {
    const first = this.props.data.first;
    const gallery = this.props.data.gallery;
    const polaroids = this.props.data.polaroids;
    const videos = this.props.data.videos;
    const options = this.props.data.options;

    const isLoading = this.state.isLoading;

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Share <span style={{ fontWeight: 700 }}>{first}</span>'s Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div>
              Package to Send :{" "}
              <span style={{ fontWeight: 800 }}>
                {this.state.selectedpackage}
              </span>
            </div>
            <div className="btn-group">
              <div></div>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Change
              </button>
              <div className="dropdown-menu">
                {gallery && (
                  <a
                    className="dropdown-item"
                    onClick={() => this.changePackage("Portfolio")}
                  >
                    Portfolio
                  </a>
                )}
                {polaroids && (
                  <a
                    className="dropdown-item"
                    onClick={() => this.changePackage("Portfolio + Pola")}
                  >
                    Portfolio + Pola
                  </a>
                )}
                {videos && (
                  <a
                    className="dropdown-item"
                    onClick={() => this.changePackage("Portfolio + Video")}
                  >
                    Porfolio + Video
                  </a>
                )}
                {gallery && polaroids && videos && (
                  <React.Fragment>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      onClick={() => this.changePackage("All")}
                    >
                      All
                    </a>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>


          {options && options[0] == "1" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px"
              }}
            >
              <div>User can generate compcard</div>
              <div>
                <Toggle
                  onClick={() => this.onToggle("compcard")}
                  on={"YES"}
                  off={"NO"}
                  size="sm"
                  offstyle="primary"
                  active={this.state.toggleActiveCompcard}
                />
              </div>
            </div>
          )}

          {options && options[1] == "1" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px"
              }}
            >
              <div>User can download pictures</div>
              <div>
                <Toggle
                  onClick={() => this.onToggle("download")}
                  on={"YES"}
                  off={"NO"}
                  size="sm"
                  offstyle="primary"
                  active={this.state.toggleActiveDownload}
                />
              </div>
            </div>
          )}

          {options && options[2] == "1" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px"
              }}
            >
              <div>User can share my profile</div>
              <div>
                <Toggle
                  onClick={() => this.onToggle("share")}
                  on={"YES"}
                  off={"NO"}
                  size="sm"
                  offstyle="primary"
                  active={this.state.toggleActiveShare}
                />
              </div>
            </div>
          )}

          <div className="input-group mb-3" style={{ marginTop: "20px" }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                @
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Send To"
              aria-label="Sendto"
              aria-describedby="basic-addon1"
              id="sendto"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Object</span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Object"
              aria-label="Object"
              aria-describedby="basic-addon1"
              id="object"
            />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Your Message</span>
            </div>
            <textarea
              className="form-control"
              aria-label="textarea"
              id="message"
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={!isLoading ? () => this.sharePackage() : null}
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Share"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ShareModal;
