import React from "react";
import queryString from "query-string";

import { Redirect } from "react-router-dom";
import httpService from "../http";
import Button from "react-bootstrap/Button";

import "../css/login.css";
//import { Md5 } from 'ts-md5/dist/md5';
import logo from "../assets/logoo3.png";
import FacebookLogin from "react-facebook-login";
import LostPwdModal from "../components/pwd/lostpwd";

function testId(mail, id) {
  console.log("testid", mail, id);
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      params: "",
      width: 0,
      height: 0,
      flexCtn: 0,
      isLoading: false,
      lostpwd: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this._goLogin = this._goLogin.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.getFingerPrint();
    window.addEventListener("resize", this.updateWindowDimensions);
    const url = window.location.href;
    let params = queryString.parse(url);
    console.log("did mount", params);
    if (params.key) {
      console.log("go home");
      httpService.setLocalStorage("key", params.key);
      this.setState({
        redirect: true,
        key: params.key,
      });
    }
  }

  async getFingerPrint() {
    let res = await httpService.getFingerPrint();
    console.log("finger print", res);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const height = document.getElementById("flexCenter").clientHeight;

    this.setState(
      { width: window.innerWidth, height: window.innerHeight, flexCtn: height },
      () => {
        console.log("height", window.innerHeight, "flexctn", height);
      }
    );
  }

  _goLogin() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.goLogin();
      }
    );
  }

  async goLogin() {
    var loginE = document.getElementById("email").value;
    var passwordE = document.getElementById("password").value;

    var Postdata = {
      action: "gologin",
      login: loginE,
      password: passwordE,
    };

    let url = "login.php";

    console.log("postData", Postdata);
    let res = await httpService.goBackOffice(url, Postdata);
    console.log("go login", res);

    if (res.status == "ok") {
      httpService.setLocalStorage("key", res.token);
      this.setState({
        isLoading: false,
        redirect: true,
      });
    } else {
      // Error Handler
      console.log("error handler");
      this.setState({
        isLoading: false,
      });
    }
  }

  componentClicked() {
    console.log("login connect");
  }

  showLostPwd() {
    this.setState({
      lostpwd: true,
    });
  }

  mgmtmodal(type) {
    switch (type) {
      case "pwdmodal":
        this.setState({
          lostpwd: false,
        });

        break;
    }
  }

  async responseFacebook(e) {
    console.log("responseFacebook", e);
    if (e.accessToken.length > 10) {
      var email = e.email;

      var Postdata = {
        action: "loginfb",
        login: email,
      };

      let url = "login.php";

      console.log("postData", Postdata);
      let res = await httpService.goBackOffice(url, Postdata);
      console.log("go login", res);

      if (res.status == "ok") {
        httpService.setLocalStorage("key", res.token);
        this.setState({
          isLoading: false,
          redirect: true,
        });
      }
    }
  }

  render() {
    let isLoading = this.state.isLoading;

    if (this.state.redirect) {
      return <Redirect to={"/home/"} />;
    }

    const margintop = Math.max(
      150,
      this.state.height / 2 - this.state.flexCtn / 2
    );

    return (
      <React.Fragment>
        <LostPwdModal
          show={this.state.lostpwd}
          action={this.mgmtmodal.bind(this)}
        />
        <div className="logo">
          <img
            src={logo}
            alt="Logo"
            style={{ height: "56px", marginTop: "15Px", zIndex: "3000" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="container" style={{ marginTop: margintop }}>
            <div className="row" style={{ justifyContent: "center" }}>
              <div
                className="col-sm-6 col-md-4 col-md-offset-4"
                id="flexCenter"
              >
                <div className="account-wall">
                  <img
                    className="profile-img"
                    src="https://lh5.googleusercontent.com/-b0-k99FZlyE/AAAAAAAAAAI/AAAAAAAAAAA/eu7opA4byxI/photo.jpg?sz=120"
                    alt=""
                  />
                  <div className="form-signin">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      required
                      autoFocus
                      id="email"
                    />
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      required
                      id="password"
                      //autoFocus
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{ marginTop: "10px" }}
                        variant="primary"
                        type="submit"
                        onClick={!isLoading ? () => this._goLogin() : null}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading…" : "Connect"}
                      </Button>

                      {
                        <FacebookLogin
                          appId="313042769245677"
                          autoLoad={false}
                          fields="name,email,picture"
                          onClick={() => this.componentClicked()}
                          callback={(e) => this.responseFacebook(e)}
                          cssClass="my-facebook-button-class"
                        />
                      }
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{
                          color: "black",
                          fontSize: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => this.showLostPwd()}
                      >
                        Forget your password ?
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
