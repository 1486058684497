import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import "./css/modal.css";
import httpService from "../../http";

class CreateAlbumModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading : false
    }
    this.setShow = this.setShow.bind(this);
  }

  setShow(elem, type) {
    this.props.action('closeCreateModal', type);
  }

  success(){
    this.handleClose('success');
  }

  handleClose(elem) {
    this.setShow('close', elem);
  }

  createAlbum(){
    this.setState({
      isLoading: true
    }, () => {
      this._createAlbum();
    })
  }

  async _createAlbum(){

    const formData = new FormData()
    formData.append('identifiant', this.props.identifiant);
    formData.append('key', httpService.getLocalStorage('key'));
    formData.append('action', 'createalbum');
    formData.append('albumname', document.getElementById('albumname').value);

    let url = 'mgmt_album.php';
    console.log('create album', this.props.identifiant);
    let res = await httpService.goBackOffice(url, formData);
    console.log('create album', res);
    if(res == 'ok'){
     
      this.setState({
        isLoading: false
      }, () => {
        this.success();
      })
    } else {
      // Error Handler
    }
  }



  render() {
    
    const isLoading = this.state.isLoading;

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
           Create a new album
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3" style={{ marginTop: "20px" }}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                @
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Album Name"
              aria-label="Sendto"
              aria-describedby="basic-addon1"
              id="albumname"
            />
          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={ () => this.handleClose() }>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={!isLoading ? () => this.createAlbum() : null}
            disabled={ isLoading }
          >
            {isLoading ?  "Loading..." : "Create" }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateAlbumModal;
