import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";

Font.register({ family: 'GothamLight', src: '/assets/GothamLight.ttf' });
Font.register({ family: 'GothamMedium', src: '/assets/GothamMedium.ttf' });
Font.register({ family: 'GothamUltra', src: '/assets/GothamUltra.ttf' });

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    marginBottom: '5mm'
  },
  logoBackground:{
    position: "absolute",
    display: "block",
    height: "100%",
    maxHeight: "100%",
    width: "auto",
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  pageBackground2: {
    position: 'absolute',
    minWidth: '100%',
    //minHeight: 'auto',
    display: 'block',
    height: 'auto',
    width: '100%',
  },
  mensu: {
    fontSize: "9pt",
    marginLeft: "3mm",
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    marginTop: '3mm'
  },
  mensuName: {
    textTransform: "uppercase",
    fontFamily: 'GothamLight',
    fontSize: "9pt",
  },
  mensuValue: {
    fontFamily: 'GothamMedium',
    marginLeft: '2mm',
    fontSize: "9pt",
  },
  modelName:{
    textTransform: "uppercase",
    fontFamily: 'GothamUltra'
  },
  backgroundImage: {
    objectFit: 'cover',
  }
});



export function PdfDocument(props) {
  console.log("pdf doc", props);
  const first = props.data.first;
  const mensurations = props.data.mensurations;

  var firstImg = props.data.gallery[0];
   var gallery = props.data.gallery;

   var data = [];
  
  for (var i = 1; i < gallery.length - 1; i++) {
    //console.log("pdf doc gallery", gallery[i]);
    var j = i + 1;
    data[i] = { gauche : gallery[i], droite: gallery[j] };
    i = i + 1;
  }

  
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View>
          <View style={{ height: "15mm" }}></View>
      
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%"
            }}
          >
            <View
              style={{
                width: "142.5mm",
                marginLeft: "5mm",
                height: "190mm"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: 'center',
                  height: "170mm"
                }}
              >
                <View style={{ width: "60mm", height: "15mm" }}>
                  <Image
                    style={styles.pageBackground}
                    source={
                      "/assets/logoo3_b.jpg"
                    }
                  />
                </View>
                <View style= {{ marginTop: '5mm'}}>
                  <Text style = {{ textTransform: "uppercase",
    fontFamily: 'GothamUltra', letterSpacing: '0.5mm'}}>{
    first
    }</Text>
                </View>

                    <View style = {{ marginTop: '5mm', display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
                    {
                    mensurations ? mensurations.map(y => {
                      return(
                        <View style={styles.mensu} key={y.name}>
                        <View>
                          <Text style={styles.mensuName}>{y.name}</Text>
                        </View>
                        <View>
                          <Text style={styles.mensuValue}>{y.valeur}</Text>
                        </View>
                      </View>
                      )
                    }) : null
                   }
                    </View>
                


              </View>
            </View>
            <View
              style={{
                width: "142.5mm",
                marginLeft: "2mm",
                height: "190mm"
              }}
            >
              <Image
                style={styles.backgroundImage}
                source={firstImg}
              />
            </View>
          </View>
        </View>
      </Page>

      {data
        ? data.map((x, index) => {
          return (
            <Page
              size="A4"
              orientation="landscape"
              style={styles.page}
              wrap={true}
            >
              <View
                style={{
                  height: "15mm",
                  display: "flex",
                  flexDirection: "row",
                  marginHorizontal: "5mm",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
           
                <View style={styles.modelName}>
                  <Text>{
                  first
                  }</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start"
                  }}
                >
                  {
                    mensurations ? mensurations.map(y => {
                      return(
                        <View style={styles.mensu} key={y.name}>
                        <View>
                          <Text style={styles.mensuName}>{y.name}</Text>
                        </View>
                        <View>
                          <Text style={styles.mensuValue}>{y.valeur}</Text>
                        </View>
                      </View>
                      )
                    }) : null
                   }

              </View>
              <View style={{ width: "40mm", height: "10mm" }}>
                <Image
                  style={styles.pageBackground}
                  source={
                    "/assets/logoo3_b.jpg"
                  }
                />
              </View>
                </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",

                height: "100%"
              }}
            >
              <View
                style={{
                  width: "142.5mm",
                  marginLeft: "5mm",
                  height: "190mm"
                }}
              >
                <Image style={styles.pageBackground2} source={x.gauche} key={x}/>
              </View>
              <View
                style={{
                  width: "142.5mm",
                  marginLeft: "2mm",
                  height: "190mm"
                }}
              >
                <Image style={styles.pageBackground2} source={x.droite} key={x}/>
              </View>
            </View>
              </Page>
  );
})
        : ""}
    </Document >
    );

}


