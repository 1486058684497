import React from "react";
import "../css/modelsummary.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import PdfModal from "./modal/pdfmodal";
import ShareModal from "./modal/sharemodal";
import ZipModal from "./modal/zipmodal";
import PublicModal from "./modal/publicmodal";

import MeetingHistory from "./meeting/headermeeting";
import httpService from "../http";
import logo from "../assets/logoo3.png";

class ModelDetailsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.downloadZip = this.downloadZip.bind(this);
    this.state = {
      pdfmodal: false,
      sharemodal: false,
      zipmodal: false,
      meetingmodal: false,
      publicmodal: false,
      width: ""
    };
    this.actionModal = this.actionModal.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    console.log("did mount", this.props);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth
    });
  }

  downloadZip() {
    this.props.action("gozip");
  }

  actionModal(elem, modaltype) {
    console.log("action pdf modal", elem, modaltype);
    switch (modaltype) {
      case "pdfmodal":
        this.setState({
          pdfmodal: elem
        });
        break;

      case "sharemodal":
        this.setState({
          sharemodal: elem
        });
        break;

      case "zipmodal":
        this.setState({
          zipmodal: elem
        });
        break;

      case "meetingmodal":
        this.setState({
          meetingmodal: elem
        });
        break;

      case "publicModal":
        this.setState({
          publicmodal: elem
        });
        break;
    }
  }

  async saveChoice(elem) {
    let data = {
      action: "savelog",
      model: this.props.identifiant,
      choice: elem
    };

    let postData = {
      key: httpService.getLocalStorage("key"),
      data: data
    };

    let url = "saveLog.php";

    console.log("postData", postData);
    await httpService.goBackOffice(url, postData);
  }

  sharePackage(elem) {
    console.log("action share modal", elem);
  }

  render() {
    let windowwidth = this.state.width;
    let backgroundImg = "url(" + this.props.background + ")";

    let menuWidth;
    let displayD = "block";

    if (windowwidth < 640) {
      console.log("mobile");
      menuWidth = 0;
    } else {
      console.log("not mobile");
      menuWidth = "260px";
    }

    return (
      <React.Fragment>

        <div>
          <div
            className="adminMenuPanel removeOnMobile"
            style={{
              minWidth: menuWidth,
              maxWidth: menuWidth,
              display: "block"
            }}
          >
            <div
              className="removeOnMobile"
              style={{
                position: "absolute",
                zIndex: 10,
                height: "100%",
                width: "100%",
                top: "0",
                left: "0",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundImage: backgroundImg
              }}
            ></div>
          </div>

          <div
            style={{
              backgroundColor: "#1f283e",
              opacity: 0.94,
              minWidth: menuWidth,
              maxWidth: menuWidth,
              height: "100vh",
              position: "absolute",
              top: 0,
              left: 0,
              color: "white",
              zIndex: 100,
              display: "block"
            }}
          >
            <div style={{ color: "white", zIndex: "20", display: displayD }}>
              <div className="logoAdmin removeOnMobile">
                <img src={logo} style={{ width: "200px" }} />
              </div>
              <div id="navMobile">
                <ul className="nav">
                  <li className="li removeOnMobile">
                    <Link to="/home/" style={{ textDecoration: "none" }}>
                      <div className={"base "}>
                        <div className="btnFlex white">
                          <div>
                            <FontAwesomeIcon
                              icon={faChevronLeft}
                              style={{
                                marginLeft: "15px",
                                marginRight: "20px",
                                fontSize: "14px"
                              }}
                            />
                          </div>
                          <div className="btnTitle">Get Back</div>
                        </div>
                      </div>
                    </Link>
                  </li>
                  {this.props.options && this.props.options[1] == 1 && (

<>
<PdfModal
          show={this.state.pdfmodal}
          data={this.props}
          action={this.actionModal.bind(this)}
          goback={this.saveChoice.bind(this)}
        />

                    <li
                      className="li "
                      onClick={() => this.actionModal(true, "pdfmodal")}
                    >
                      {" "}
                      <div className={"base "}>
                        <div className="btnFlex white">
                          <div>
                            {" "}
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{
                                marginLeft: "15px",
                                marginRight: "20px",
                                fontSize: "14px"
                              }}
                            />
                          </div>
                          <div className="btnTitle ">Download PDF</div>
                        </div>
                      </div>
                    </li>
                    </>
                  )}

                  {this.props.options && this.props.options[2] == 1 && (
                    <>
                      <ZipModal
                        show={this.state.zipmodal}
                        data={this.props}
                        action={this.actionModal.bind(this)}
                        goback={this.saveChoice.bind(this)}
                      />
                      <li
                        className="li"
                        onClick={() => this.actionModal(true, "zipmodal")}
                      >
                        {" "}
                        <div className={"base "}>
                          <div className="btnFlex white">
                            <div>
                              {" "}
                              <FontAwesomeIcon
                                icon={faFileArchive}
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "20px",
                                  fontSize: "14px"
                                }}
                              />
                            </div>
                            <div className="btnTitle ">Download ZIP</div>
                          </div>
                        </div>
                      </li>
                    </>
                  )}

                  {this.props.options && this.props.options[0] == 1 && (
                    <>
                      <ShareModal
                        show={this.state.sharemodal}
                        data={this.props}
                        action={this.actionModal.bind(this)}
                        goback={this.saveChoice.bind(this)}
                      />

                      <li
                        className="li"
                        onClick={() => this.actionModal(true, "sharemodal")}
                      >
                        {" "}
                        <div className={"base "}>
                          <div className="btnFlex white">
                            <div>
                              {" "}
                              <FontAwesomeIcon
                                icon={faShareAlt}
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "20px",
                                  fontSize: "14px"
                                }}
                              />
                            </div>
                            <div className="btnTitle">Share</div>
                          </div>
                        </div>
                      </li>
                    </>
                  )}

                  {this.props.admin == true && (
                    <>
                      <PublicModal
                        show={this.state.publicmodal}
                        data={this.props}
                        action={this.actionModal.bind(this)}
                        goback={this.saveChoice.bind(this)}
                      />

                      <li
                        className="li"
                        onClick={() => this.actionModal(true, "publicModal")}
                      >
                        {" "}
                        <div className={"base"}>
                          <div className="btnFlex white">
                            <div>
                              {" "}
                              <FontAwesomeIcon
                                icon={faLink}
                                style={{
                                  marginLeft: "15px",
                                  marginRight: "20px",
                                  fontSize: "14px"
                                }}
                              />
                            </div>
                            <div className="btnTitle">Public Link</div>
                          </div>
                        </div>
                      </li>
                    </>
                  )}

                  {this.props.meeting == "ok" && (
                    <div>
                      <MeetingHistory data={this.props} />
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModelDetailsHeader;
