import React from "react";
import "../modal/css/modal.css";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MeetingModal from "../modal/meetingmodal";

import httpService from "../../http";

class MeetingHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { past: '', show: false };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(elem) {
    console.log("toggle modal", elem);
    //this.props.action(elem, 'meetingmodal');
    this.setState({
      show: false
    });
  }

  setPast(elem) {
    console.log("past", elem);
    this.setState({
      past: elem
    });
  }

  showMeetingModal() {
    this.setState({
      show: true
    });
  }

  makeChoice(data) {
    console.log("make choice", data);
    this.setPast(data);
    this.saveChoice(data);
  }

  async saveChoice(elem) {
    let data = {
      action: "savelog",
      model: this.props.data.identifiant,
      choice: elem
    };

    let postData = {
      key: httpService.getLocalStorage("key"),
      data: data
    };

    let url = "saveLog.php";

    console.log("postData", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("res models", res);
    //this.setData(res);
  }

  async componentDidMount(){
    let res = await this.getPast();
    console.log('did mount', res);
    this.setState({
      past : res.status
    })
  }

  async getPast(){
    let data = {
      action: "getpast",
      model: this.props.data.identifiant,
    };

    let postData = {
      key: httpService.getLocalStorage("key"),
      data : data
    };
    let url = "saveLog.php";
    console.log("postData", postData);
    let res = await httpService.goBackOffice(url, postData);
    return res;
  }

  render() {
    let past = this.state.past;

    return (
      <React.Fragment>
        <MeetingModal
          show={this.state.show}
          data={this.props.data}
          action={this.toggleModal.bind(this)}
          makechoice={this.makeChoice.bind(this)}
        />
        <div className="rightCtn">
          {past == "nolog" && (
            <React.Fragment>
              <div
                className="rightMenuElem"
                style={{ padding: ".5rem .8rem" }}
                onClick={() => this.makeChoice("nointerest")}
              >
                {" "}
                <FontAwesomeIcon icon={faBan} style={{ marginRight: "6px" }} />
                Pass on her
              </div>

              <div
                className="entoureCtn blink"
                onClick={() => this.makeChoice("interest")}
              >
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  style={{ marginRight: "6px" }}
                />
                Request an Appointment
              </div>
            </React.Fragment>
          )}

          {past == "interest" && (
            <div
              className="validMeeting"
              onClick={() => this.showMeetingModal()}
            >
              <FontAwesomeIcon
                icon={faThumbsUp}
                style={{ marginRight: "6px" }}
              />
              Meeting is Set
            </div>
          )}

          {past == "nointerest" && (
            <div
              className="invalidMeeting"
              onClick={() => this.showMeetingModal()}
            >
              <FontAwesomeIcon
                icon={faThumbsDown}
                style={{ marginRight: "6px" }}
              />
              You pass on her
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default MeetingHistory;
