import React from "react";
import "../css/modelsummary.css";

import { Actions } from "./actions";
import logo from "../assets/logoo3.png";
import { Link } from "react-router-dom";


class Modelsumary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const portraitImg = "url(" + this.props.portrait + ")";

    return (
     
        <React.Fragment>


      <div className="modelsummary">
        <div className="avatar" style={{ backgroundImage: portraitImg }}>
          <Actions identifiant={this.props.identifiant} admin = { this.props.admin } isagency = { this.props.isagency } />
          <div className="comment">
          <Link
          to={{ pathname: "/modeldetails/" + this.props.identifiant }}
          style={{ textDecoration: "none", color: "white" }}
        >
            <div className="commentCtn">

   
              {this.props.pictures.video && this.props.pictures.video > 0 && (
                <div>{this.props.pictures.video} Vidéo </div>
              )}

              {this.props.pictures.polaroid &&
                this.props.pictures.polaroid > 0 && (
                  <div>{this.props.pictures.polaroid} Polaroids </div>
                )}

              {this.props.pictures.gallery &&
                this.props.pictures.gallery > 0 && (
                  <div>{this.props.pictures.gallery} Pictures </div>
                )}
                
            </div>
            </Link>
          </div>
        </div>
      
        
 <Link
          to={{ pathname: "/modeldetails/" + this.props.identifiant }}
          style={{ textDecoration: "none", color: "white" }}
        >
        
      
        <div className="name">
          {this.props.first} {this.props.last}
        </div>
        
          
  </Link>
          
        
      
      </div>
      </React.Fragment>
    );
  }
}

export default Modelsumary;
