
import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";

import httpService from "../../http";
import ProgressBar from 'react-bootstrap/ProgressBar'
import axios from "axios"


class UploadMovieModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indicateur: 0
        }
        this.fileUpload = this.fileUpload.bind(this);
    }

    handleClose(type = 'none') {
        this.props.action('', 'uploadmoviemodal', type);
    }

    async fileUpload(file) {

        const formData = new FormData()
        formData.append('file', file);
        formData.append('action', 'savevideo');
        formData.append('albumid', this.props.albumid);
        formData.append('identifiant', this.props.identifiant);
        formData.append('key', httpService.getLocalStorage('key'));

        let url = 'https://bmyscouter.co/db/fullpackage/mgmt_album.php';

        formData.key = httpService.getLocalStorage('key');

        const config = {
            onUploadProgress: function(progressEvent) {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              console.log(percentCompleted);
              this.setState({
                indicateur: percentCompleted,
            });

            }.bind(this)
          }

        let res = await axios.post(url, formData, config)
            .then(function (response) {
                console.log('events', response.data);
                return response.data;
            })
            .catch(function (error) {
                console.log(error)
            });

        return res;

    }

    async uploadPics() {
        console.log('upload pics', this.props.files);
        var indicateurE;

        for (var i = 0; i < this.props.files.length; i++) {

            let res = await this.fileUpload(this.props.files[i]);
            console.log('file', res);
            if (res !== 'ok') {
                // Create Error Allert
                break;
            }
        }
        this.handleClose('success');
    }


    render() {

        let files = this.props.files;

        return (
            <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Movie</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ProgressBar animated now={this.state.indicateur} label={`${this.state.indicateur}%`} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Cancel
          </Button>
                    <Button variant="primary" onClick={() => this.uploadPics()}>
                        Upload
          </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UploadMovieModal;
