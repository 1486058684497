import React from "react";
import ChartistGraph from "react-chartist";

import "../../../css/graphitem.css";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import httpService from "../../../http";


class GraphComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats : [],
    };

  }

  async componentDidMount() {
    var temp;

    if(this.props.type == 'Line'){
      temp = 'getdailyvisit';
    }

    if(this.props.type == 'Bar'){
      temp = 'getmainlocation';
    }

    let res = await this.getData(temp);
    this.setState({
     stats : res.stats
    }, () => {
      console.log('this state', this.state);
    })

    }


 async getData(elem){
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: elem
    };
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    console.log('get data', elem, 'res', res);
    return res;
  }
  
  render() {

    var options;
    if(this.props.type == 'Line'){
      options = {
        high: 10,
        low: 0,
        showArea: true,
        lineColor: "blue",
      };
    }

    if(this.props.type == 'Bar'){
      options = {
        high: 100,
        low: 0,
        seriesBarDistance: 10
      };
/*
      var data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
          [3, 2, 9, 5, 4, 6, 4, 6, 7, 8, 7, 4]
        ]
      };
*/
    }


    var type = this.props.type;

    var classNames = require("classnames");
    var btnClass = classNames({
      colorGraph: true,
      green: this.props.color == "green",
      orange: this.props.color == "orange",
      red: this.props.color == "red"
    });

    return (
      <React.Fragment>
        <React.Fragment>
          <div className="graphItem graphBase">
            <div className="graphCard">
              <div className={btnClass}>
                <ChartistGraph
                  data={this.state.stats}
                  options={options}
                  type={type}
                />
              </div>

              <div className="graphBody">
                <div className="CardColor left">{this.props.title}</div>
                <div className="CardColorSubtitle left">{this.props.subtitle}</div>
                <div className="CardColorFooter"></div>
              </div>

              <div className="graphFooter">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: '10px'
                  }}
                >
                  <div> <FontAwesomeIcon
                    icon={faClock}
                    style={{

                      fontSize: "14px"
                    }}
                  /></div>
                  <div style = {{ marginLeft: '10px' }}>{this.props.footer}</div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default GraphComp;
