import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import httpService from '../../http';


class JpegModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quality: "High"
    };
  }

  change(qualityD) {
    this.setState({
      quality: qualityD
    });
  }

  setShow(elem) {
      this.setState({
          quality: 'High'
      }, () => {
        this.props.action(elem, "jpegmodal");
      })

  }

  handleClose() {
    this.setShow(false);
  }

  handleShow() {
    this.setShow(true);
  }

  downloadPics(){
      let imgUrl = '';
      
      switch(this.state.quality){
        case 'High':
        imgUrl = this.props.data.fullurl;
        break;

        case 'Medium':
            imgUrl = this.props.data.resize;
        break;
      }
      this.saveChoice('download');
      this.createDl(imgUrl, 'pics.jpg');
  }

  async saveChoice(elem){

    let data = {
      'action' : 'savelog',
      'model' : this.props.identifiant,
      'choice' : elem
    };

    let postData = {
      key: httpService.getLocalStorage("key"),
      data : data
    };

    let url = "saveLog.php";

    console.log("postData", postData);
    await httpService.goBackOffice(url, postData);
    }


  createDl(url, fileName){
 
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
}

  render() {
    // const pdfName = this.props.data.first + '.pdf';

    let backImg2 = this.props.data.resize;

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>Download Image</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div>
              <img
                src={backImg2}
                style={{
                  height: "320px",
                  width: "auto",
                  maxWidth: '480px',
                  border: "2px solid black"
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px",
                width: "100%"
              }}
            >
              <div>
                Choose Quality :{" "}
                <span style={{ fontWeight: "800" }}>
                  {" "}
                  {this.state.quality}{" "}
                </span>{" "}
              </div>
              <div>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Change
                  </button>

                  <div className="dropdown-menu">
                    <a
                      className="dropdown-item"
                      onClick={() => this.change("Medium")}
                    >
                      Medium
                    </a>

                    <a
                      className="dropdown-item"
                      onClick={() => this.change("High")}
                    >
                      High
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleClose()}>
            Close
          </Button>
          <Button variant="primary" onClick={() => this.downloadPics()}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default JpegModal;
