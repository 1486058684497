import React from "react";
import "../css/modelsummary.css";
import "../css/album.css";

import { ActionsDetails } from './actions';
import polaimg from "../assets/photo.png"

class Album extends React.Component {
  constructor(props) {
    super(props);
  }

  showAlbum(albumid){
    this.props.action('showalbum', albumid);
  }

  render() {
    const data = this.props.data;
    let backgroundImg;
    if(data.id !== 'videos'){
      if(data.id){
        backgroundImg = "url(" + data.background + ")";
     } else {
       backgroundImg = "url('https://seemodels.co/assetsMobile/fullpackage/vide.jpg')";
     }
    
    } else {
      backgroundImg = "url('https://seemodels.co/assetsMobile/fullpackage/video.jpg')";
    }


    return (
      <div className={"card " + (data.desc == 'Polaroids' ? 'albumPola': null)} onClick={ () => this.showAlbum(data.id) }>
        <div
          className="card-image"
          style={{ backgroundImage: backgroundImg }}
        >
          {
            data.desc == 'Polaroids' && (
              <div style = {{ position: 'absolute', top: '5px', right : '5px' }}>
              <img src={polaimg} style = {{ height: '35px' }} />
            </div>
            )
          }

        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "5px",
            color: "black"
          }}
        >
          {
            data.id !== 'videos' && (
              <>
              <div>Total Pics : {data.totalpics}</div>
              <div>Public Pics : {data.visiblepics}</div>
              </>
            )
          }

{
            data.id == 'videos' && (
              <>
              <div>Total Videos : {data.totalpics}</div>
              <div>Public Videos : {data.visiblepics}</div>
              </>
            )
          }
  
  
        </div>
      </div>
    );
  }
}

class AllAlbum extends React.Component {

  action(elem, elem1){
    console.log('action', elem, elem1);
    this.props.action(elem, elem1);
  }

  render() {

    return (
      <div className="AlbumCtn">
        {this.props.data &&
          this.props.data.map(x => {
            return (
              <div>
                <Album data={x} action={ this.action.bind(this) } />
              </div>
            );
          })}
      </div>
    );
  }
}

export { AllAlbum };
