import React from 'react';
import JsZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

class CustomFunc extends React.Component{


  goZip = async (data, zipname, picsname) => {
    var zip = new JsZip();
   
    for (var i = 0; i < data.length; i++) {
      var filename = "pics_" + picsname + "_" + i + ".jpg";
      zip.file(filename, this.urlToPromise(data[i]), { binary: true });
    }

        await zip.generateAsync({ type: "blob" }).then(function(content) {
         
          saveAs(content, zipname); 
      
        });
        return 'ok';
  };

  urlToPromise(url) {
    return new Promise(function(resolve, reject) {
      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }


}

export default new CustomFunc();

