import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import "./css/modal.css";

//import CustomFunc from "../../customfunc";
import JsZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import ProgressBar from 'react-bootstrap/ProgressBar'

class ZipModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quality: "Medium",
      content: "Portfolio",
      isLoading3: false,
      indicateur: 0,
      indicateurBool: false,
    };
    this.change = this.change.bind(this);
    this.downloadZip = this.downloadZip.bind(this);
    this.goZip = this.goZip.bind(this);
  }

  change(vartochange, value) {
    console.log("change", vartochange, value);
    this.setState(
      {
        [vartochange]: value
      },
      () => {
        console.log("state", this.state);
      }
    );
  }

  setShow(elem) {
    this.props.action(elem, "zipmodal");
  }

  handleClose() {
    this.props.goback("zip");
    this.setShow(false);
  }

  handleShow() {
    this.setShow(true);
  }

  downloadZip() {
    this.setState(
      {
        isLoading3: true
      },
      () => {
        console.log("before switch", this.state);
        this._goZip();
      }
    );
  }

  _goZip() {
    let content;

    switch (this.state.content) {
      case "Portfolio":
        content = this.props.data.gallery;
        break;

      case "Polaroids":
        content = this.props.data.polaroids;
        break;

      case "Portfolio + Pola":
        content = this.props.data.gallery.concat(this.props.data.polaroids);
        break;
    }
    let zipname = this.props.data.first + "_" + "_pics.zip";

    const temp = [];
    for (var i = 0; i < content.length; i++) {
      if (this.state.quality == "Medium") {
        temp[i] = content[i].resize;
      }
      if (this.state.quality == "High") {
        temp[i] = content[i].fullurl;
      }
    }
    this.setState({
      indicateurBool: true,
      isLoading3: true
    }, () => {
      this.goZip(temp, zipname, this.props.data.first);
    })

  }

  async goZip(content, zipname, first) {
    console.log('go zip', content);
    let content2 = await this.PrepareZip(content, first, zipname);
    this.setState({
      indicateurBool: false,
      isLoading3 : false
    })
  }


  async PrepareZip(content, first, zipname){
    var zip = new JsZip();
    for (var i = 0; i < content.length; i++) {
      console.log('content i', content[i]);
      const indicateurE = (i / content.length * 100).toFixed(0);

      this.setState({
        indicateur: indicateurE,
      }) 
        var filename = "pics_" + first + "_" + i + ".jpg";
        zip.file(filename, this.urlToPromise(content[i]), { binary: true });

      }

      await zip.generateAsync({ type: "blob" }).then(function(content) {
        saveAs(content, zipname); 
      });
    }
      



  urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  render() {
    const first = this.props.data.first;
    const gallery = this.props.data.gallery;
    const polaroids = this.props.data.polaroids;

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Download <span style={{ fontWeight: 700 }}>{first}</span>'s Zip
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div>
              Choose what to zip :{" "}
              <span style={{ fontWeight: 700 }}>{this.state.content}</span>
            </div>

            <div className="btn-group">
              <div></div>
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Change
              </button>

              <div className="dropdown-menu">
                {gallery && (
                  <a
                    className="dropdown-item"
                    onClick={() => this.change("content", "Portfolio")}
                  >
                    Portfolio
                  </a>
                )}
                {polaroids && (
                  <a
                    className="dropdown-item"
                    onClick={() => this.change("content", "Polaroids")}
                  >
                    Polaroids
                  </a>
                )}
                {gallery && polaroids && (
                  <React.Fragment>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      onClick={() => this.change("content", "Portfolio + Pola")}
                    >
                      Portfolio + Pola
                    </a>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px"
            }}
          >
            <div>
              Choose quality :{" "}
              <span style={{ fontWeight: 700 }}>{this.state.quality}</span>
            </div>

            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Change
              </button>

              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  onClick={() => this.change("quality", "Medium")}
                >
                  Medium
                </a>

                <a
                  className="dropdown-item"
                  onClick={() => this.change("quality", "High")}
                >
                  High
                </a>
              </div>
            </div>
          </div>

          {
             this.state.indicateurBool && (
              <div style = {{ width: '100%', marginTop: '10px' }}>
              <ProgressBar animated  now={this.state.indicateur} label={`${this.state.indicateur}%`} />
              </div>
             )
           }


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleClose()}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={!this.state.isLoading3 ? () => this.downloadZip() : null}
            disabled={this.state.isLoading3}
          >
            {this.state.isLoading3 ? "Loading…" : "Download"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ZipModal;
