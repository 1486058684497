import React from "react";
import httpService from "../../http";


import { AllAlbum } from "../album";

import Picturesblock from "../picturesblock";
import Nav from "./nav";

import ZipAlbumModal from "../modal/zipalbum";
import CreateAlbumModal from "../modal/createalbum";

class MainAlbum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: [],
      gallery: [],
      videos : [],
      albumBool: true,
      albumid: "",
      portrait: "",
      ZipAlbumModal: false,
      CreateAlbumModal: false,
      identifiant: "",
      showtype : 'album',
      rights : []
    };
    this.picturesblock = React.createRef();
    this.setData = this.setData.bind(this);
  }

  async componentDidMount() {
    this.getAlbum();
    this.setData(
      "identifiant",
      this.getParams(window.location.pathname, "/modelalbum/main/", "")
    );
  }

  setData(variable, res) {
 
    this.setState({
      [variable]: res
    }, () => {
      console.log(this.state);
    });
  }

  async showAlbum(albumidE) {
    let postData = {
      key: httpService.getLocalStorage("key"),
      model: this.getParams(window.location.pathname, "/modelalbum/main/", ""),
      action: "getalbumdetails",
      albumid: albumidE
    };

    let url = "getAlbums.php";
    console.log("postData", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("showAlbum", res);
 
    this.setData("albumBool", false);
    this.setData("admin", true);
    if(albumidE == "videos"){
      this.setData("showtype", "videos");
      this.setData("videos", res);
    } else {
      this.setData("showtype", "album");
      this.setData("gallery", res);
    }
  }

  action(elem, elem1) {
    console.log("action", elem, elem1);
    switch (elem) {
      case "showalbum":
        this.setData("albumid", elem1);
        this.showAlbum(elem1);
        break;

        case "openzipmodal":
 this.setData("ZipAlbumModal", elem1);
          break;

      case "close album":
        this.setData("albumBool", true);
        break;

      case "addpics":
        this.addPics();
        break;

        case "addvideo":
          this.addPics();
          break;

      case "refresh":
        this.showAlbum(elem1);
        break;

      case "refreshallalbum":
        this.getAlbum();
        break;

      case "delete":
        this.deleteImg(elem1);
        break;

      case "createalbum":
        this.setData("CreateAlbumModal", true);
        break;

      case "closeCreateModal":
        this.closeCreateModal(elem1);
        break;
      case "togglepublic":
        this.togglePublic(elem1);
        break;
    }

    console.log("action", elem, elem1);
  }

  closeCreateModal(type) {
    this.setData("CreateAlbumModal", false);
    if (type == "success") {
      this.getAlbum();
    }
  }

  togglePublic(imgid) {
    const temp = [...this.state.gallery];
    let index = this.searchIndex(temp, imgid);

    if (temp[index].status == 0) {
      temp[index].status = 1;
    } else {
      temp[index].status = 0;
    }
    this.setState(
      {
        gallery: temp
      },
      () => {
        console.log("etat", this.state.gallery);
        this.toggleImageVisibility(imgid, temp[index].status);
      }
    );
  }

  searchIndex(array, imgid) {
    var i = 0;
    let index;
    for (i = 0; i < array.length; i++) {
      if (array[i].id == imgid) {
        index = i;
      }
    }
    return index;
  }

  deleteImg(imgid) {
    // Sarch Index
    const temp = [...this.state.gallery];
    let index = this.searchIndex(temp, imgid);

    //filter
    temp.splice(index, 1);
    this.setState(
      {
        gallery: temp
      },
      () => {
        this.deleteImgOnServer(imgid);
      }
    );
  }

  async toggleImageVisibility(imgid, statusD) {
    let postData = {
      key: httpService.getLocalStorage("key"),
      model: this.getParams(window.location.pathname, "/modelalbum/main", ""),
      action: "toggleimagevisibility",
      imgid: imgid,
      status: statusD
    };
    let url = "mgmt_album.php";
    console.log("postdata", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("status", res);
  }

  async deleteImgOnServer(imgid) {
    let postData = {
      key: httpService.getLocalStorage("key"),
      model: this.getParams(window.location.pathname, "/modelalbum/main", ""),
      action: "deletepics",
      imgid: imgid
    };
    let url = "mgmt_album.php";
    console.log("postdata", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("delete", res);
  }

  addPics() {
    this.picturesblock.current.upload(
      this.state.albumid,
      this.state.identifiant
    );
  }

  addVideo(){
    this.picturesblock.current.upload(
      this.state.albumid,
      this.state.identifiant
    );
  }

  async getAlbum() {
    let postData = {
      key: httpService.getLocalStorage("key"),
      model: this.getParams(window.location.pathname, "/modelalbum/main/", ""),
      action: "getallalbums"
    };
    let url = "getAlbums.php";
    console.log("postData", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("res models", res);
    this.setData("albums", res.albums);
    this.setData("portrait", res.portrait);
    this.setData("rights", res.rights);
  }

  getParams(string, value, replace) {
    return string.replace(value, replace);
  }

  render() {
    return (
      <div className="main">
        <Nav
          title="Albums"
          action={this.action.bind(this)}
          etat={this.state.albumBool}
          showtype = { this.state.showtype }
          rights = { this.state.rights }
        />
        <ZipAlbumModal
          first="test"
          show={this.state.ZipAlbumModal}
          content={this.state.gallery}
          identifiant={this.state.identifiant}
          action={this.action.bind(this)}
        />
        <CreateAlbumModal
          show={this.state.CreateAlbumModal}
          action={this.action.bind(this)}
          identifiant={this.state.identifiant}
        />

        {this.state.albumBool && (
          <AllAlbum data={this.state.albums} action={this.action.bind(this)} />
        )}
        {!this.state.albumBool && (
          <Picturesblock
            ref={this.picturesblock}
            key={this.state.identity}
            gallery={this.state.gallery}
            videos={this.state.videos}
            showtype={ this.state.showtype }
            action={this.action.bind(this)}
            admin={this.state.admin}
            rights={this.state.rights}
          />
        )}
      </div>
    );
  }
}

export default MainAlbum;
