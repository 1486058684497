import React, { useLayoutEffect, useState } from "react";

import "../css/album.css";
import "../css/admin.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link
} from "react-router-dom";

import MainAlbum from "../components/albums/main";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/logoo3.png";


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function ShowWindowDimensions(props) {
  const [width, height] = useWindowSize();
  return { width };
}

function getParams(string, value, replace) {
  return string.replace(value, replace);
}



function ModelAlbums() {

  let { path, url } = useRouteMatch();
  var identifiant = getParams(window.location.pathname, '/modelalbum/main/', '');
  identifiant = getParams(identifiant, '/modelalbum/photographer/', '');
  identifiant = getParams(identifiant, '/modelalbum/publicprofile/', '');
  //path = window.location.pathname;
  var pathname = window.location.pathname;
  console.log('identifiant', identifiant, '-path-', path);
  let windowwidth = ShowWindowDimensions().width;


  let menuWidth = '260px';
  let displayD = 'block';
  console.log("window width", windowwidth);
  if (windowwidth < 640) {
    console.log("mobile");
    menuWidth = 0;
  } else {
    console.log("not mobile");
    menuWidth = "260px";
  }


  return (
    <React.Fragment>

      <div >

        <div
          className="adminMenuPanel removeOnMobile"
          style={{ minWidth: menuWidth, maxWidth: menuWidth, display: "block" }}
        >
          <div
            className="removeOnMobile"
            style={{
              position: "absolute",
              zIndex: 10,
              height: "100%",
              width: "100%",
              top: "0",
              left: "0",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            //  backgroundImage: 'url("https://bmyscouter.co/db/clients/model/2019/12/user6d41fdc2efa2ed86ddc51f4b03d9c676/pics_ee502ea6ad0313f4a90fb712761b8cf8_materials_light.jpeg")'
            }}
          ></div>
        </div>

        <div

          style={{
            backgroundColor: "#1f283e",
            opacity: 0.94,
            minWidth: menuWidth,
            maxWidth: menuWidth,
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            color: "white",
            zIndex: 100,
            display: "block"
          }}
        >
          <div style={{ color: "white", zIndex: "20", display: 'block' }}>
            <div className="logoAdmin removeOnMobile">
              <img src={logo} style={{ width: "200px" }} />
            </div>
            <div id="navMobile">
              <ul className="nav" >

                <li className="li removeOnMobile">
                  <Link to="/home/" style={{ textDecoration: 'none' }}>
                    <div className={"base "}>
                      <div className="btnFlex white">
                        <div>
                          <FontAwesomeIcon
                            icon={faChevronLeft}
                            style={{
                              marginLeft: "15px",
                              marginRight: "20px",
                              fontSize: "14px"
                            }}
                          />
                        </div>
                        <div className="btnTitle">Get Back</div>
                      </div>
                    </div>
                  </Link>
                </li>

                <li className="li">

                  <Link to={`${url}/main/` + identifiant} style={{ textDecoration: 'none' }}>
                    <div className={"base " +
                      (pathname == "/modelalbum/main/" + identifiant ? "active" : null)}>
                      <div className="btnFlex white">
                        <div>
                          <FontAwesomeIcon
                            icon={faImage}
                            style={{
                              marginLeft: "15px",
                              marginRight: "20px",
                              fontSize: "14px"
                            }}
                          />
                        </div>
                        <div className="btnTitle">Albums</div>
                      </div>
                    </div>
                  </Link>
                </li>

              {
                /*

                                <li className="li">
                  <Link to={`${url}/photographer/` + identifiant } style={{ textDecoration: 'none' }}>
                    <div className={"base " +
                      (pathname == "/modelalbum/photographer/" + identifiant ? "active" : null)}>
                      <div className="btnFlex white">
                        <div>
                          <FontAwesomeIcon
                            icon={faCameraRetro}
                            style={{
                              marginLeft: "15px",
                              marginRight: "20px",
                              fontSize: "14px"
                            }}
                          />
                        </div>
                        <div className="btnTitle">photographer</div>
                      </div>
                    </div>
                  </Link>
                </li>

                <li className="li">
                  <Link to={`${url}/publicprofile/` + identifiant } style={{ textDecoration: 'none' }}>
                    <div className={"base " +
                      (pathname == "/modelalbum/publicprofile/" + identifiant ? "active" : null)}>
                      <div className="btnFlex white">
                        <div>
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{
                              marginLeft: "15px",
                              marginRight: "20px",
                              fontSize: "14px"
                            }}
                          />
                        </div>
                        <div className="btnTitle">Public Profile</div>
                      </div>
                    </div>
                  </Link>
                </li>

                */
              }





              </ul>
            </div>

          </div>
        </div>
      </div>

      <Switch>
        <Route  path={ `${path}/main/:identifiant` } >
          <MainAlbum />
        </Route>

        <Route  path={ `${path}/photographer/:identifiant` }>
          <div>Photpgrapher</div>
        </Route>

        <Route  path={ `${path}/publicprofile/:identifiant` }>
          <div>publicprofile</div>
        </Route>


      </Switch>


    </React.Fragment>
  );
}

export default ModelAlbums;
