import React from "react";

import "../../../css/graphitem.css";
import httpService from "../../../http";

class UpdateProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      avatar : '',
      name : '',
      status : '',
    };
  }

  async componentDidMount() {
    let res = await this.getInfos();
    this.setData(res);
    }

    setData(elem){
      this.setState({
        avatar : elem.avatar,
        name : elem.first + ' ' + elem.last,
        status : elem.status
      })
    }

  async getInfos(){
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: 'getprofilesummary'
    };
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    console.log('res', res);
    return res;
  }

  render() {
    return (
      <div className="summaryCtn">
        <div className="card-profile">
          <div className="card-avatar">
            <div>
              <img
                className="img"
                src={this.state.avatar}
              />
            </div>
          </div>
          <div className="card-body">
            <div className="card-model-title">{this.state.status}</div>
            <div className="card-model-name">{this.state.name}</div>
            <div className="card-desc">
              Don't be scared of the truth because we need to restart the human
              foundation in truth And I love you like Kanye loves Kanye I love
              Rick Owens’ bed design but the back is...
            </div>

            <div className="card-btn round">Change Avatar</div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateProfile;
