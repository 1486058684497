import React from "react";
import Modal from "react-bootstrap/Modal";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "../compcard";

import Button from "react-bootstrap/Button";
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar'


class PdfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      mensurations: this.props.data.mensurations,
      first: this.props.data.first,
      gallery: [],
      indicateur: 0,
      indicateurBool: false,
      quality: 'Medium'
    };
    this.preparePdf = this.preparePdf.bind(this);
    this.change = this.change.bind(this);
  }

  async preparePdf() {
    var temp = await this.getAllBase64(this.props.data.gallery);

      this.setState({
        gallery: temp,
        show: true,
        indicateurBool : false
      });
    
  }

  async componentDidMount() {
    console.log("did mount pdf modal", this.props);
  }

  setShow(elem) {
    this.props.action(elem, "pdfmodal");
  }

  handleClose() {
    this.props.goback("pdf");
    this.setShow(false);
  }

  async getAllBase64(data) {
    var temp = [];
    for (var i = 0; i < data.length; i++) {
      const indicateurE = (i / data.length * 100).toFixed(0);

      this.setState({
        indicateur: indicateurE,
        indicateurBool : true
      });
      if(this.state.quality == 'Medium'){
        temp[i] = await this.getBase64(data[i].resize);
      }

      if(this.state.quality == 'High'){
        temp[i] = await this.getBase64(data[i].fullurl);
      } 
    }
    if(temp !== 'error'){
      return temp;
    }
  
  }

  async getBase64(url) {
  
    var uri =
      "https://bmyscouter.co/db/shared/crop.php?img=" +
      url +
      "&width=538&height=720";
     // console.log('url', uri);
    let res = await axios
      .get(uri)
      .then(function(response) {
        console.log("get base64 success", url);
        var temp = "data:image/jpeg;base64," + response.data;
        return temp;
      })

      .catch(function(error) {
        console.log("get base64 error", error);
      });

    return res;
  }

  handleShow() {
    this.setShow(true);
  }

  change(vartochange, value) {
    console.log("change", vartochange, value);
    this.setState(
      {
        [vartochange]: value
      },
      () => {
        console.log("state", this.state);
      }
    );
  }

  render() {
    const pdfName = this.props.data.first + "_compcard_seemodels.pdf";

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>Download PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Please not that you can select/unselect some pics and generate a custom Compcard.
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: '100%'
            }}
          >
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              width: '100%'
            }}
          >
            <div>
              Choose quality :{" "}
              <span style={{ fontWeight: 700 }}>{this.state.quality}</span>
            </div>

            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Change
              </button>

              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  onClick={() => this.change("quality", "Medium")}
                >
                  Medium
                </a>

                <a
                  className="dropdown-item"
                  onClick={() => this.change("quality", "High")}
                >
                  High
                </a>
              </div>
              </div>
              </div>

            <div>
              
              <Button variant="secondary" onClick={() => this.preparePdf()}>
                Prepare
              </Button>
            </div>
           
           {
             this.state.indicateurBool && (
              <div style = {{ width: '100%', marginTop: '10px' }}>
              <ProgressBar animated  now={this.state.indicateur} label={`${this.state.indicateur}%`} />
              </div>
             )
           }
         
  
            <div style = {{   marginTop: '15Px',                 marginBottom: '15Px' }}>
              {this.state.show && (
                <PDFDownloadLink
                  style={{
                    border: "1px solid transparent",
                    padding: ".375rem .75rem",
                    fontSize: "1rem",
                    lineHeight: "1.5",
                    borderRadius: ".25rem",
                    textDecoration: 'none',
                    backgroundColor: '#007bff',
                    borderColor: '#007bff',
    
                  }}
                  document={<PdfDocument data={this.state} />}
                  fileName={pdfName}
                  className="rightMenuElem"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download Pdf"
                  }
                </PDFDownloadLink>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PdfModal;
