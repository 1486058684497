import React from "react";
import "../css/modelsummary.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faUsersCog } from "@fortawesome/free-solid-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";


import { Link } from "react-router-dom";

class Actions extends React.Component {
  render() {
    return (
      <div className="actionCtn">

            <Link
            to={{ pathname: "/modeldetails/" + this.props.identifiant }}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="action">
              <div style={{ marginLeft: "15px" }}>
                <FontAwesomeIcon icon={faEye} />
              </div>
              <div style={{ marginLeft: "10px" }}>Public</div>
            </div>
          </Link>
       
        {
          this.props.isagency && (
            <div className="action">
            <Link
              to={{ pathname: "/modelalbum/main/" + this.props.identifiant }}
              style={{ textDecoration: "none", color: "white" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start"
                }}
              >
                <div style={{ marginLeft: "15px" }}>
                  <FontAwesomeIcon icon={faImage} />
                </div>
                <div style={{ marginLeft: "10px" }}>Albums</div>
              </div>
            </Link>
          </div>
          )
        }

        {this.props.admin && (
          <React.Fragment>
            <div className="action">
              <Link
                to={{ pathname: "/modelalbum/main/" + this.props.identifiant }}
                style={{ textDecoration: "none", color: "white" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start"
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FontAwesomeIcon icon={faImage} />
                  </div>
                  <div style={{ marginLeft: "10px" }}>Albums</div>
                </div>
              </Link>
            </div>

            <div className="action">
              <Link
                to={{ pathname: "/modeladmin/dashboard" }}
                style={{ textDecoration: "none", color: "white" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start"
                  }}
                >
                  <div style={{ marginLeft: "15px" }}>
                    <FontAwesomeIcon icon={faUsersCog} />
                  </div>
                  <div style={{ marginLeft: "10px" }}>Admin</div>
                </div>
              </Link>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

class ActionsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.unSelect = this.unSelect.bind(this);
  }

  componentDidMount() {
    console.log('action detials', this.props);
  }

  unSelect(elem) {
    console.log("unselect");
    this.props.onclick(elem, "unselect");
  }

  delete(elem) {
    console.log("delete");
    this.props.onclick(elem, "delete");
  }

  jPeg(elem) {
    this.props.onclick(elem, "jpeg");
  }

  togglePublic(elem) {
    this.props.onclick(elem, "togglepublic");
  }

  render() {
    return (
      <div className="actionCtn2">
        {
          !this.props.admin && (
            <div
            className="action2"
            onClick={() => this.jPeg(this.props.identifiant)}
          >
            <div className="actionTitle" style={{ marginLeft: "15px" }}>
              <FontAwesomeIcon icon={faEye} />
            </div>
            <div className="actionTitle" style={{ marginLeft: "10px" }}>
              Jpeg
            </div>
          </div>
          )
        }


        {( ( (this.props.showtype == "album") || (this.props.showtype == "polaroids") ||  (this.props.showtype == "gallery") ) && this.props.admin) && (
          <>
            <div
              className="action2"
              onClick={() => this.delete(this.props.imgid)}
            >
              <div className="actionTitle" style={{ marginLeft: "15px" }}>
                <FontAwesomeIcon icon={faFileArchive} />
              </div>
              <div className="actionTitle" style={{ marginLeft: "10px" }}>
                Delete
              </div>
            </div>

            <div
              className="action2"
              onClick={() => this.togglePublic(this.props.imgid)}
            >
              <div className="actionTitle" style={{ marginLeft: "5px" }}>
                <FontAwesomeIcon icon={faUserSecret} />
              </div>
              <div className="actionTitle" style={{ marginLeft: "10px", whiteSpace: 'nowrap' }}>
                {this.props.status == 0 && (
                  <div>Go Public</div>
                )}


                {this.props.status == 1 && (
                  <div>Go Private</div>
                )}

              </div>
            </div>
          </>
        )}

        {this.props.showtype !== "album" && (
          <div
            className="action2"
            onClick={() => this.unSelect(this.props.identifiant)}
          >
            <div className="actionTitle" style={{ marginLeft: "15px" }}>
              <FontAwesomeIcon icon={faFileArchive} />
            </div>
            <div className="actionTitle" style={{ marginLeft: "10px" }}>
              Unselect
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { Actions };
export { ActionsDetails };
