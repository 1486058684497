import React from "react";
import ChartistGraph from "react-chartist";

import "../../../css/graphitem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";

import { faClock } from "@fortawesome/free-solid-svg-icons";

import httpService from "../../../http";

class CardComp extends React.Component {
  render() {
    var classNames = require("classnames");
    var btnClass = classNames({
      cardIcon: true,
      orange: this.props.color == "orange",
      green: this.props.color == "green",
      red: this.props.color == "red",
      blue: this.props.color == "blue"
    });

    let icon;
    switch (this.props.color) {
      case "orange":
        icon = faImages;
        break;

      case "green":
        icon = faDollarSign;
        break;

      case "red":
        icon = faFileContract;
        break;

      case "blue":
        icon = faSlidersH;
        break;
    }

    return (
      <React.Fragment>
        <React.Fragment>
          <div className="cardStats cardStatsBase">
            <div className="cardContent">
              <div className="cardHeader">
                <div className={btnClass}>
                  <FontAwesomeIcon
                    icon={icon}
                    style={{
                      marginLeft: "15px",
                      marginRight: "20px",
                      fontSize: "14px"
                    }}
                  />
                </div>

                <div style={{ marginTop: "50px" }}>
                  <div className="CardColor">{this.props.title}</div>
                  <div className="CardColorSubtitle">{this.props.subtitle}</div>
                </div>
              </div>

              <div className="cardFooter">
                <div className="cardFooterTitle">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: '10px'
                    }}
                  >
                    <div> <FontAwesomeIcon
                      icon={faClock}
                      style={{

                        fontSize: "14px"
                      }}
                    /></div>
                    <div style={{ marginLeft: '10px' }}>At 16 March</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}


class AllCardComp extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      nbreimage: '',
      nbrecollab: '',
      ppvisit: '',
      totalrevenue: ''
    };
  }

  async componentDidMount() {
    let res = await this.getInfos();
    this.setData(res);
  }

  setData(elem) {
    this.setState({
      nbreimage: elem.nbreimage,
      nbrecollab: elem.nbrecollab,
      ppvisit: elem.ppvisit,
      totalrevenue: elem.totalrevenue
    })
  }

  async getInfos() {
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: 'getcardinfos'
    };
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    console.log('res', res);
    return res;
  }

  render() {

    return (
      <React.Fragment>
        {
          this.state.nbreimage && (
            <CardComp title={'Total Pictures'} subtitle={this.state.nbreimage + ' / 500'} color={'orange'} />
          )
        }

        {
          this.state.totalrevenue && (
            <CardComp title={'Revenue From SeeMo'} subtitle={this.state.totalrevenue + ' € '} color={'green'} />
          )
        }

        {
          this.state.nbrecollab && (
            <CardComp title={'Nbre of Representations'} subtitle={this.state.nbrecollab} color={'red'} />
          )
        }

        {
          this.state.ppvisit && (
            <CardComp title={'Total Visit '} subtitle={this.state.ppvisit} color={'blue'} />
          )
        }

      </React.Fragment>
    );
  }
}

export default AllCardComp;



