import React from "react";
import AdminNav from "./components/adminnav";

import "../../css/contractadmin.css";
import httpService from "../../http";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

class Contract extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      documents: null,
      contract: null
    };
  }

  async componentDidMount() {
    let res = await this.getData();
    console.log('res', res);
    this.setState({
      documents: res.documents,
      contract: res.contract
    }, () => {
      console.log('this state', this.state);
    })
  }

  async getData() {
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: 'getmydocuments'
    };
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    return res;
  }

  goLink(url){
    window.open(url);
  }

  render() {
    return (
      <React.Fragment>
        <div className="dashboardCtn">
          <AdminNav title={'Contract'} icon="ok" />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "50px"
            }}
          >

            {
              this.state.contract && (
                <div className="card2">
                <div className="cardHeader2 purple">
                  <div className="cardNoteHeader">My Representation Contract</div>
                </div>
                <div className="cardBody2">
                  <table style={{ width: '100%', maxWidth: '100%', fontSize: '1.063rem', fontFamily: 'Roboto,Helvetica,Arial,sans-serif', marginTop: '20px' }}>
                    <tbody>
  
                      <tr>
                        <td>
                          Type
                        </td>
  
                        <td>
                          début
                        </td>
  
                        <td>
                          Download
                        </td>
                      </tr>
  
                      {this.state.contract && this.state.contract.map((x, index) => {
  
                
                        return (
                          <>
                            <tr style={{ borderBottom: '2px solid rgba(0,0,0,.06)' }}>
                            <td>
                              {x.type}
                            </td>
                          
                              <td>
                                {x.debut}
                              </td>
                              <td onClick={ () => this.goLink(x.url) }> 
                              <FontAwesomeIcon icon={ faDownload } className="meetingIcon" style = {{ cursor: 'pointer' }} />
                         
                              </td>
  
                            </tr>
  
                          </>
  
                        )
                      })
                      }
  
  
  
  
  
                    </tbody>
                  </table>
                </div>
              </div>
  
              )
            }

            {
              this.state.documents && (
                <div className="card2">
                <div className="cardHeader2 orange" >
                  <div className="cardNoteHeader">My Documents</div>
                </div>
                <div className="cardBody2">
                  <table style={{ width: '100%', maxWidth: '100%', fontSize: '1.063rem', fontFamily: 'Roboto,Helvetica,Arial,sans-serif', marginTop: '20px' }}>
                    <tbody>
  
                      <tr style={{ borderBottom: '2px solid rgba(0,0,0,.06)' }}>
                        <td>
                          Agency
                      </td>
                        <td>
                          Country
                      </td>
                        <td>
                          Start Date
                      </td>
                        <td>
                          Contract
                      </td>
                        <td>
                          Ending Date
                      </td>
                      </tr>
  
  
  
  
                    </tbody>
                  </table>
                </div>
              </div>
  
    
  
              )
            }
          
          </div>



        </div>


      </React.Fragment>
    );
  }
}

export default Contract;
