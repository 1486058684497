import React from "react";

import "../../../css/contractadmin.css";
import './customInput.css'

import {  CustomSingleSelect } from '../../common/slider';
import httpService from "../../../http";
//import { SelectFiled } from './custominput';


class ColorProfile extends React.Component {

  
  constructor() {
    super();
  this.state = {
    data : [

    ]
  };
}

setData(elem){

  this.setState({
    data : elem.select
  }, () => {
    console.log('this state', this.state.data);
  })
  }

  async getData(){
 
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: 'getmodelmensu',
    };
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    console.log( 'res', res);
    return res;
  }


  async componentDidMount(){
    let res = await this.getData();
    this.setData(res);
  }

  changeValue(value, part){
    console.log('change', value, part);
    let temp = [...this.state.data];
    let index = this.findIndex(temp, part);
    console.log('change index', index);

    if(index !== -1){
      temp[index].value = value;
      this.setState({
        data : temp
      })
    }


  }

  findIndex(array, value){
    var i = 0;
    let index = -1;
    for(i; i < array.length; i++){
      if(array[i].part == value){
        index = i;
      }
    }
    return index;
  }


  async goUpdate(){

    var DATA = [];
    for(var i = 0; i < this.state.data.length; i++){
      DATA[i] = this.state.data[i].value;
    }
  
    var LABELS = [];
    for(var j = 0; j < this.state.data.length; j++){
      LABELS[j] = this.state.data[j].part;
    }
  
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: 'updatemodalinfos',
      data : {'data' : DATA, 'labels' : LABELS}
    };
  
    console.log('postdata', postData);
  
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    console.log( 'res', res);
  
    }
  
  

  render() {


    return (
      <div className="c">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "15px"
          }}
        >
          <div className="card2">
            <div className="cardHeader2 purple">
              <div className="cardNoteHeader">Edit Colors</div>
            </div>
            <div className="cardBody2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <div>
                  {
                    this.state.data && this.state.data.map( (x, index) => {
                      return (
                        <CustomSingleSelect 
                        user_part={ x.name } 
                        isMulti={ false } 
                        options = { x.options } 
                        value = { x.value }
                        change = { this.changeValue.bind(this) }
                        part = { x.part }
                        />


                      )
                    })
                  }
                </div>

              </div>
            </div>

            <div className="card-footer">
              <div
              style = {{ display: 'flex', flexDirection : 'row' }}
             className="mobicenter"
             onClick = { () => this.goUpdate() }
              >
                <div className="card-btn rectangle">Update Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ColorProfile;
