import axios from "axios"
import React from 'react';
import queryString from "query-string"

import Fingerprint2 from 'fingerprintjs2'

class httpService extends React.Component{

 
  async  getFingerPrint(){
        let res;
        if (window.requestIdleCallback) {
           res = await requestIdleCallback(function () {
                Fingerprint2.get(function (components) {
                  var values = components.map(function (component) { return component.value })
            var murmur = Fingerprint2.x64hash128(values.join(''), 31)
            console.log('fingerprint', murmur);
            return murmur;
                })
            })
        } else {
           res =  setTimeout(function () {
                Fingerprint2.get(function (components) {
                  var values = components.map(function (component) { return component.value })
            var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                console.log('fingerprint', murmur);
                return murmur;
                })  
            }, 500)
        }
        return res;
    }

    getParameters(type){
        const url = window.location.href;
        let params = queryString.parse(url);
       
        if(type ==='key'){
            const key = params["key"];
            return key;
        }
        if(type === 'models'){
            const models = params["models"];
            return models;
        }
    }

    getLocalStorage(elem){
            const result = localStorage.getItem(elem)
            return result;
    }

    setLocalStorage(type, elem){
        localStorage.setItem(type, elem);
    }

    getUrl(url){
        let base = 'https://bmyscouter.co/db/fullpackage/';
        return base + url;
    }

   async goBackOffice(url, postData){
        postData.key = this.getLocalStorage('key')
        console.log('url', this.getUrl(url) );

       
        let axiosConfig = {
            headers: {
             //   'Content-Type': 'application/json;charset=UTF-8',
              //  "Access-Control-Allow-Origin": "*",
            }
          };
          
    let res = await axios.post( this.getUrl(url), postData)
    .then(function (response) {
        console.log('events', response.data);
        return response.data;
    })

    .catch(function(error) {;
        console.log(error)
    });

    return res;
}

/*
async goBackOffice (url, data) {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(data)
      
    };

    let res = await fetch( this.getUrl(url), requestOptions)
        .then(response =>  response.json())
        .then(data => {
            console.log('response', data);
            return data;
        });
    return res;
}*/


}

export default new httpService();