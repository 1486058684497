import React from "react";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import "./css/modal.css";
import Toggle from "react-bootstrap-toggle";

import httpService from "../../http";

class PublicModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publiclink: "", toggleStatus : '', isLoading : false
    };
  }

  async componentDidMount() {
    let temp = await this.getPublicLink();
    console.log('link', temp);
    this.setState({
      publiclink: temp.pplink,
      toggleStatus : temp.status
    }, () => {
        console.log('state', this.state);
    });
  }

  async getPublicLink() {
    var postData = {
      action: "getpubliclink",
      key: httpService.getLocalStorage("key"),
      model: this.props.data.identifiant
    };

    let url = "mgmt_album.php";
    let res = await httpService.goBackOffice(url, postData);
    return res;
  }

  setShow(elem) {
    this.props.action(elem, "publicModal");
  }

  handleClose() {
    this.setShow(false);
  }

  handleShow() {
    this.setShow(true);
  }

  onToggle(elem) {
    switch (elem) {
      case "status":
        this.setState({ toggleStatus: !this.state.toggleStatus });
        break;
    }
  }

  goPublic(){
      window.open(this.state.publiclink);
  }

  async savePublicProfil(){
    var postData = {
        action: "mgmtppstatus",
        key: httpService.getLocalStorage("key"),
        model: this.props.data.identifiant,
        ppstatus : this.state.toggleStatus
      };
  
      let url = "mgmt_album.php";
      let res = await httpService.goBackOffice(url, postData);
      if(res == 'ok'){
        this.handleClose();
      }
  }

  render() {
    const first = this.props.data.first;

    const isLoading = this.state.isLoading;

    return (
      <Modal show={this.props.show} onHide={() => this.handleClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontWeight: 700 }}>{first}</span>'s Public Link
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style = {{ display : 'flex', flexDirection : 'column', justifyContent: 'center', width: '100%' }}>

            <div style = {{ textAlign : 'center', fontWeight: '800', cursor: 'pointer' }} onClick= { () => this.goPublic() }> {this.state.publiclink} </div>

            <div   style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                
              }} >

            <div>Public Profile status</div>
              <div>
                <Toggle
                  onClick={() => this.onToggle("status")}
                  on={"Active"}
                  off={"Inactive"}
                  size="sm"
                  offstyle="primary"
                  active={this.state.toggleStatus}
                />
              </div>
  
            </div>

            </div>
           
            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={!isLoading ? () => this.savePublicProfil() : null}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PublicModal;
