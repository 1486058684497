import React from 'react';
import './picto.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import { faSquare  } from "@fortawesome/free-solid-svg-icons";
import { faTh } from "@fortawesome/free-solid-svg-icons";


const Picto = (props) => {

    if(props.type == 'small'){
        return(
            <FontAwesomeIcon
            icon={faTh}
            className={ 'pictoImg ' + (props.status == 3 ? 'pictoActive': 'pictoinActive') }
          />
        )
    }

    if(props.type == 'medium'){
        return(
            <FontAwesomeIcon
            icon={faThLarge}
            className={ 'pictoImg ' + (props.status == 2 ? 'pictoActive': 'pictoinActive') }
          />
        )
    }

    if(props.type == 'large'){
        return(
            <FontAwesomeIcon
            icon={faSquare}
            className={ 'pictoImg ' + (props.status == 1 ? 'pictoActive': 'pictoinActive') }
          />
        )
    }

}


export default Picto;