import React from "react";

import "../../../css/graphitem.css";
import httpService from "../../../http";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";

import moment from 'moment'

class NoteComp extends React.Component {

  render() {
    var classNames = require("classnames");

    var btnClass = classNames({
      cardNoteHeader : true,
      purple: this.props.color == "purple",
      purpleshadow : this.props.color == "purple",
      orange: this.props.color == "orange",
    });

    var noteClass = classNames({
      noteCard : true,
      noteCard_half : this.props.resize !== null,
      noteCard_full : this.props.resize == null
    })

    return (
      <React.Fragment>
        <React.Fragment>
<div class={noteClass}>
    <div class="noteCatedContent">
        <div class={ btnClass }>{this.props.title}</div>
        <div class="cardNoteBody">
            <table style = {{ width: '100%' }}>
            <thead></thead>
            <tbody>
              {
                this.props.data && this.props.data.map( (x,index) => {
                  return(
                    <tr>
                    <td>
                      { x.type == 'photograph' && (
                        <FontAwesomeIcon icon={ faCamera } className="meetingIcon" />
                      )}
                      {
                        x.type == 'agency' && (
                          <FontAwesomeIcon icon={ faBriefcase } className="meetingIcon" />
                        )
                      }
                      {
                        x.type == 'castbookings' && (
                          <FontAwesomeIcon icon={ faEuroSign } className="meetingIcon" />
                        )
                      }
                    </td>
                    <td>{x.client}</td>
                    <td>{ moment(x.from, "").fromNow() }</td>
                    <td>{x.address}</td>
                    <td>Details</td>
                  </tr>
                  )

                })
              }
         
            </tbody>
            </table>

        </div>
    </div>
</div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}


class AllNoteComp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      past : [],
      future : []
    };

  }

  async componentDidMount() {
    let res = await this.getInfos();
    this.setData(res);
  }

  setData(elem){
    this.setState({
      past : elem.past,
      future : elem.future
    })
  }

  async getInfos(){
    let postData = {
      key: httpService.getLocalStorage("key"),
      action: 'getcompastevent'
    };
    let url = "mgmt_dashboard.php";
    let res = await httpService.goBackOffice(url, postData);
    console.log('res', res);
    return res;
  }

  render(){
    return(
      <>
      {
        this.state.future && (
          <NoteComp title={ 'Upcoming Events' } color = { 'purple' } data = { this.state.future } resize = { this.state.past } />
        )
      }
      {
        this.state.past && (
<NoteComp title={ 'Past Events' }  color = { 'orange' } data = { this.state.past } resize = { this.state.future } />
        )
      }
         
            
      </>
    )
  }


}

export default AllNoteComp;
