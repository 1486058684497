import React from 'react';
import './customInput.css'
import Select from 'react-select';

import "../../common/slider.css";

  



function ValidField (props){

  function handleChange(e, part){
    console.log('handle change' , e.target.value, part);
    props.change(e.target.value, part);
  }


    return (
 
      <div className="customDiv">
        <div className="customLabel">{ props.text } </div>
        <input className="customInput" placeholder = { props.text } value= { props.value || '' }  onChange={ (e) => handleChange(e, props.id) } />
      </div>
 
    );
  
}


function SelectFiled (props){

  function getValue(value){
    if(!props.isMulti){
      return props.options.filter(x => x.label === value);
    } else {
      var temp = [];
      console.log('get value2', value, 'text', props.text);
      for(var i = 0; i < value.length; i++){
       temp[i] = props.options.filter(x => x.label === value[i]);     
      }

      console.log('get value',temp, value);
      return temp[0] + temp[1];
    }
   
  }

  function change(value, part){
    console.log('change',value);
    props.change(value.label, part);
  }

  return (

    <div className="customDiv">
      <div className="customLabel">{ props.text } </div>
      <Select isMulti={props.isMulti} placeholder={props.text} options={props.options} value={ getValue(props.value) } onChange={ (e) => change(e, props.part) } />
    </div>

  );

}


export { SelectFiled, ValidField } ;
