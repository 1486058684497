import React from "react";
import AdminNav from "./components/adminnav";

import UpdateProfile from "./components/updateprofile";
import MensuProfile from "./components/mensuprofile"
import ColorProfile from "./components/colorprofile";
import SpecProfile from "./components/specprofile";

import "./userprofile.css"

class UserProfile extends React.Component {
  render() {
    return (

        <div className="dashboardCtn">
          <AdminNav title={"User Profile"} icon="ok" />

          <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'row', justifyContent : 'center', alignItems: 'center' }}>

            <div className="wrapper " >
              <UpdateProfile />
              <MensuProfile />
              <ColorProfile />
              {
                /*
   <SpecProfile />
                */
              }
           
      
            </div>

          </div>

        </div>
  
    );
  }
}

export default UserProfile;
