import React from "react";
import ModelDetailsHeader from "../components/modeldetailsheader";

import Mensublock from "../components/mensublock";
import Picturesblock from "../components/picturesblock";

import httpService from "../http";

import ModelDetailsBlock from "../components/modeldetails/nav";

class ModelDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      material: [],
      video: [],
      show: false,
      showtype: "gallery",
      first: "",
      last: "",
      options: "",
      identifiant: this.props.match.params.identifiant
    };
    this.picturesblock = React.createRef();
    // this.mgmtModal = this.mgmtModal.bind(this);
  }

  async componentDidMount() {
    this.setupBeforeUnloadListener();

    let postData = {
      key: httpService.getLocalStorage("key"),
      model: this.props.match.params.identifiant,
      action: "getonemodel"
    };
    let url = "getOneModels.php";
    console.log("postData", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("res models", res);
    this.setData(res);
  }

  setData(data) {
    let newmensus = [];
    const mensurations = data.data.mensurations;
    for (var i = 0; i < mensurations.length; i++) {
      newmensus[i] = {
        name: mensurations[i].name,
        valeur: this.checkUnity(
          mensurations[i].valeur,
          mensurations[i].name,
          "fr"
        )
      };
    }

    if (data.data.materials.gallery) {
      this.setState({
        showtype: "gallery"
      });
    } else if (data.data.materials.polaroids) {
      this.setState({
        showtype: "polaroids"
      });
    } else {
      this.setState({
        showtype: "videos"
      });
    }

    const model = data.first + " " + data.last;
    this.setState({
      gallery: data.data.materials.gallery,
      polaroids: data.data.materials.polaroids,
      videos: data.data.materials.videos,
      admin: data.data.admin,
      mensurations: newmensus,
      show: true,
      first: data.first,
      last: data.last,
      identity: model,
      meeting: data.meeting,
      options: data.options,
      portrait: data.data.materials.portrait
    });
  }

  checkUnity(n, part, units) {
    if (part !== "Shoe" && part !== "Hair" && part !== "Eyes") {
      if (units == "fr") {
        const extension = " cm";
        let res = n + extension;
        return res;
      } else {
        const extension = "";
        let realFeet = n / 30.48;
        if (part == "Height") {
          let feet = Math.floor(realFeet);
          let inches = Math.round((realFeet - feet) * 12);
          let res = feet + "'" + inches + '" ';
          return res;
        } else {
          let inches = Math.round(realFeet * 12);
          let res = inches + '" ';
          return res;
        }
      }
    } else {
      // Si Shoes
      return n;
    }
  }

  goSlice = index => {
    if (this.state.showtype == "gallery") {
      const temp = [...this.state.gallery];
      temp.splice(index, 1);
      this.setState({
        gallery: temp
      });
    }

    if (this.state.showtype == "polaroids") {
      const temp = [...this.state.polaroids];
      temp.splice(index, 1);
      this.setState({
        polaroids: temp
      });
    }
  };

  switchType(type) {
    this.setState({
      showtype: type
    });
  }

  goAction(elem, index = 0) {
    console.log("go action", elem, index);
    switch (elem) {
      case "gozip":
        this.goZip();
        break;

      case "unselect":
        this.goSlice(index);
        break;

      case "switchtype":
        this.switchType(index);
        break;

      case "jpeg":
        this.jpegDownload(index);
        break;

        case "togglepublic":
          this.togglePublic(index);
          break;
    }
  }

  togglePublic(imgid) {
  
    const temp = [...this.state.gallery];
    let index = this.searchIndex(temp, imgid);
    console.log('toggle', imgid, index, temp[index]);



    temp.splice(index,1);

    this.setState(
      {
        gallery: temp
      },
      () => {
        console.log("etat", this.state.gallery);
        this.toggleImageVisibility(imgid, 0);
      }
    );
  }

  searchIndex(array, imgid) {
    var i = 0;
    let index;
    for (i = 0; i < array.length; i++) {
      if (array[i].id == imgid) {
        index = i;
      }
    }
    return index;
  }

  async toggleImageVisibility(imgid, statusD) {
    let postData = {
      key: httpService.getLocalStorage("key"),
      model: this.getParams(window.location.pathname, "/modeldetails/", ""),
      action: "toggleimagevisibility",
      imgid: imgid,
      status: statusD
    };
    let url = "mgmt_album.php";
    console.log("postdata", postData);
    let res = await httpService.goBackOffice(url, postData);
    console.log("status", res);
  }

  setupBeforeUnloadListener() {
    window.addEventListener("beforeunload", ev => {
      ev.preventDefault();
      console.log("close");
      return this.doLogBeforeUnload();
    });
  }

  getParams(string, value, replace) {
    return string.replace(value, replace);
  }




  async doLogBeforeUnload() {
    let data = {
      action: "savelog",
      model: this.props.match.params.identifiant,
      choice: "close"
    };

    let postData = {
      key: httpService.getLocalStorage("key"),
      data: data
    };

    let url = "saveLog.php";

    console.log("postData", postData);
    await httpService.goBackOffice(url, postData);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.show && (
          <React.Fragment>
            <ModelDetailsHeader
              action={this.goAction.bind(this)}
              gallery={this.state.gallery}
              polaroids={this.state.polaroids}
              videos={this.state.videos}
              mensurations={this.state.mensurations}
              first={this.state.first}
              identifiant={this.state.identifiant}
              meeting={this.state.meeting}
              options={this.state.options}
              background={this.state.portrait}
              admin={this.state.admin}
            />

            <div className="main">
              <ModelDetailsBlock
                title="Public Profile"
                //  action={this.action.bind(this)}
                //  etat={this.state.albumBool}
              />

              <Mensublock
                mensurations={this.state.mensurations}
                first={this.state.first}
              />
              <Picturesblock
                ref="picturesblock"
                key={this.state.identity}
                gallery={this.state.gallery}
                polaroids={this.state.polaroids}
                videos={this.state.videos}
                first={this.state.first}
                last={this.state.last}
                identifiant={this.state.identifiant}
                action={this.goAction.bind(this)}
                showtype={this.state.showtype}
                admin={this.state.admin}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ModelDetails;
