import React from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ModelDetails from "./pages/modeldetails";
import Home from "./pages/home";
import Login from "./pages/login";
import ModelAlbums from "./pages/modelalbums";
import ModelAdmin from "./pages/modeladmin";


class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/home/" component={Home} />

              <Route
                exact
                path="/modeldetails/:identifiant"
                component={ModelDetails}
              />
              <Route
                
                path="/modelalbum"
                component={ModelAlbums}
              />

              <Route  path="/modeladmin" component={ModelAdmin} />
       
     
            </Switch>
          </Router>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
