import React from "react";

import "../../../css/contractadmin.css";
import './customInput.css'


import { ValidField , SelectFiled}  from "./custominput";

//import CustomLoader from '../../../../src/components/common/loader';
import httpService from "../../../http";

import { CustomSingleSelect } from "../../common/slider";


class UpdateProfile extends React.Component {

  constructor() {
    super();
  this.state = {
    first : '',
    last : '',
    email : '',
    phone : '',
    instagram: '',
    nationality : '',
    morphotype : ''
  };
this.setData = this.setData.bind(this);
this.goUpdate = this.goUpdate.bind(this);
}

async componentDidMount(){
  let res = await this.getData();
  this.setData(res);
}

setData(elem){
  this.setState({
    first : elem.user_first,
    last : elem.user_last,
    email : elem.user_email,
    phone : elem.user_phone,
    instagram : elem.user_igaccount,
    nationality : elem.user_nationality,
    morphotype : elem.user_morphotype
  })
}

async getData(){
  let postData = {
    key: httpService.getLocalStorage("key"),
    action: 'getmodelinfos'
  };
  let url = "mgmt_dashboard.php";
  let res = await httpService.goBackOffice(url, postData);
  console.log( 'res', res);
  return res;
}

async goUpdate(){

  var DATA = [
  this.state.first,
  this.state.last,
 this.state.email,
 this.state.phone,
 this.state.morphotype
  ];

  var LABELS = [
    'user_first',
    'user_last',
    'user_email',
    'user_phone',
    'user_morphotype'
  ];

  let postData = {
    key: httpService.getLocalStorage("key"),
    action: 'updatemodalinfos',
    data : {'data' : DATA, 'labels' : LABELS}
  };

  let url = "mgmt_dashboard.php";
  let res = await httpService.goBackOffice(url, postData);
  console.log( 'res', res);

  }

  handleChange(value, part){
    console.log('handle change', value, part);
    this.setState({
      [part] : value
    })
  }

  


  render() {

    const options = [
      { value: 'Caucasian', label: 'Caucasian' },
      { value: 'Indian', label: 'Indian' },
      { value: 'Asian', label: 'Asian' },
      { value: 'African', label: 'African' },
      { value: 'Latina', label: 'Latina' },
      { value: 'Arabic', label: 'Arabic' },
      { value: 'Other', label: 'Other' }
    ]
  

    return (
      <>
    
      <div className="a">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "15px"
          }}
        >
          <div className="card2">
            <div className="cardHeader2 purple">
              <div className="cardNoteHeader">Edit Profile</div>
            </div>


                <div className="cardBody2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <div>

             
                        <div className="customWrapper">
                        <ValidField text="First Name" value = {this.state.first} id="first" change = { this.handleChange.bind(this) } />
                        <ValidField text="Last Name" value = {this.state.last} id="last" change = { this.handleChange.bind(this) } />
                        <ValidField text="Email" value = {this.state.email} id="email" change = { this.handleChange.bind(this) } />
                        <ValidField text="Phone" value = {this.state.phone} id="phone" change = { this.handleChange.bind(this) } />
    
    
                      </div>         
    
                      <div className="customWrapper">
    
                        <ValidField text="Instagram" value = {this.state.instagram} id="igaccount" change = { this.handleChange.bind(this) }  />
                        <ValidField text="Nationality" value = {this.state.nationality} id="nationality" change = { this.handleChange.bind(this) } />
                        <SelectFiled part="morphotype" text="Morphotype" isMulti={false} id="morphotype"  options={options}  value = { this.state.morphotype } change = { this.handleChange.bind(this) } />

                        {
                          /*

  <SelectFiled text="Spoken Lang." isMulti={true} id="spokenlanguage" options={options} value = { ['Caucasian', 'Indian']} change = { this.handleChange.bind(this) } />
                          */
                        }
                      
    
                      </div>   
              
 
                    
  
                  </div>
  
                </div>
              </div>
  

            <div className="card-footer">
              <div
              style = {{ display: 'flex', flexDirection : 'row' }}
             className="mobicenter"
              >
                <div className="card-btn rectangle" onClick={ () => this.goUpdate() }>Update Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </>
    );
  }
}

export default UpdateProfile;
