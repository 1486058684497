import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import "./nav.css";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "",
      height: ""
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  createAlbum() {
    this.props.action("createalbum");
  }

  downloadAlbum() {
    this.props.action("openzipmodal", true);
  }

  addPics() {
    this.props.action("addpics");
  }

  addVideos(){
    this.props.action("addvideo");
  }

  closeAlbum() {
    this.props.action("close album");
  }

  render() {
    return (
      <React.Fragment>
        <div class="topbarCtn">
          <div
            style={{
              color: "#a9afbbd1",
              height: "50px",
              fontSize: "1.125rem",
              lineHeight: "30px",
              padding: ".625rem 0",
              fontWeight: "300",
              marginLeft: "1rem"
            }}
          >
            {this.state.width < 992 && (
              <>
                <Link
                  to="/home/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{ marginRight: "20px", fontSize: "18px" }}
                  />
                  Back
                </Link>
              </>
            )}

            {this.state.width > 992 && <>{this.props.title}</>}
          </div>

          {this.props.etat && this.props.rights.isRoot && (
            <>
   
              <div style = {{ display : 'flex', flexDirection : 'row', justifyContent: 'space-evenly', marginRight: '10px' }}>
              <div className="iconCtn" onClick={() => this.createAlbum()}>
                {" "}
                <FontAwesomeIcon icon={faImage} className="iconCustom" />
                <div style = {{ whiteSpace : 'nowrap' }}>Create Album</div>
              </div>
              <div className="iconCtn" onClick={() => this.closeAlbum()}>
                {" "}
                <FontAwesomeIcon icon={faShareAlt} className="iconCustom" />
                <div style = {{ whiteSpace : 'nowrap' }}>Share Album</div>
              </div>
              </div>

            </>
          )}

          {!this.props.etat && (
            <>


              <div className="iconCtn" onClick={() => this.closeAlbum()}>
                {" "}
                <FontAwesomeIcon icon={faTimes} className="iconCustom" />
                <div>Close Album</div>
                </div>

                {
                  this.props.showtype && this.props.showtype !== 'videos' && (
                    <>
              <div className="iconCtn" onClick={() => this.downloadAlbum()}>
                {" "}
                <FontAwesomeIcon icon={faDownload} className="iconCustom" />
                <div>Download Album</div>
              </div>
              {
                this.props.rights.isRoot && (
                  <div className="iconCtn" onClick={() => this.addPics()}>
                  {" "}
                  <FontAwesomeIcon icon={faPlus} className="iconCustom" />
                  <div>Add Pics</div>
                </div>
                )
              }
     
                    </>
                  )
                }

                {
                  this.props.showtype && this.props.showtype == 'videos' && this.props.rights.isRoot && (
                    <div className="iconCtn" onClick={() => this.addVideos()}>
                    {" "}
                    <FontAwesomeIcon icon={faPlus} className="iconCustom" />
                    <div>Add Videos</div>
                  </div>
                  )
                }

            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Nav;
